import { createSlice } from '@reduxjs/toolkit';
import { gettingAgencyList, gettingInternalStatusList, gettingPropInternalStatusList } from './filtersAsyncThunk';

const initialState = {
    internalStatus: {
        internalStatus: null,
        isLoading: false,
        error: null,
    },
    agency: {
        agency: null,
        isLoading: false,
        error: null,
    },
};

export const financialRequestSlice = createSlice({
    name: 'financialRequest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // get all requests
            .addCase(gettingInternalStatusList.pending, (state) => {
                state.internalStatus.isLoading = true;
            })
            .addCase(gettingInternalStatusList.fulfilled, (state, action) => {
                state.internalStatus.isLoading = false;
                state.internalStatus.error = null;
                state.internalStatus.internalStatus = action.payload;
            })
            .addCase(gettingInternalStatusList.rejected, (state, action) => {
                state.internalStatus.isLoading = false;
                state.internalStatus.error = action.payload.message;
            })

            .addCase(gettingPropInternalStatusList.pending, (state) => {
                state.internalStatus.isLoading = true;
            })
            .addCase(gettingPropInternalStatusList.fulfilled, (state, action) => {
                state.internalStatus.isLoading = false;
                state.internalStatus.error = null;
                state.internalStatus.internalStatus = action.payload;
            })
            .addCase(gettingPropInternalStatusList.rejected, (state, action) => {
                state.internalStatus.isLoading = false;
                state.internalStatus.error = action.payload.message;
            })

            .addCase(gettingAgencyList.pending, (state) => {
                state.agency.isLoading = true;
            })
            .addCase(gettingAgencyList.fulfilled, (state, action) => {
                state.agency.isLoading = false;
                state.agency.error = null;
                state.agency.agency = action.payload;
            })
            .addCase(gettingAgencyList.rejected, (state, action) => {
                state.agency.isLoading = false;
                state.agency.error = action.payload.message;
            })

    },
});

export default financialRequestSlice.reducer;
