import React, { useState } from 'react'
import './ForgotPass.scss'
import { useForm } from "react-hook-form";
import Input from 'components/Input/Input';
import { Link } from 'react-router-dom';

const ForgotPass = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [pass, setPass] = useState("")

    const onChangeHandler = (name, value) => {
        setPass(value)
    }

    const onSubmit = (data) => {
        console.log(data)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='forgot-pass'>
            <h1>Mot de passe oublié ?</h1>
            <Input value={pass} errors={errors} register={register} name="email" onChange={onChangeHandler} label="Adresse Email *" placeholder="nom@exemple.com" type="text" />
            <button type='submit' className='beige-button button'>
                <span>Générer un nouveau mot de passe</span>
            </button>
            <Link to='/login' className='back'>Retour à la page d'identification</Link>
        </form>
    )
}

export default ForgotPass