import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { displayLoading } from './legalNoticeLoader';
import { gettingLegalNoticePage } from 'redux/slices/static-pages/staticPagesAsyncThunk';

import './LegalNotice.scss';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const LegalNotice = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.staticPages.legal);

  useEffect(() => {
    dispatch(gettingLegalNoticePage());
  }, [dispatch]);

  const displayLegalNoticeContent = () => {
    if (data && !isLoading) {
      const htmlString = data;
      const reactElement = parse(htmlString);

      return reactElement;
    }
  };

  return isLoading ? (
    displayLoading()
  ) : (
    <div className='legal'>
      <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
        <Link className='bread-link' to='/'>
          Accueil
        </Link>
        <Typography color='text.primary'>Mentions légales</Typography>
      </Breadcrumbs>
      {displayLegalNoticeContent()}
    </div>
  );
};

export default LegalNotice;
