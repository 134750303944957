import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import MyProfile from 'assets/myProfile.png';
import Logout from 'assets/logout.png';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/slices/authentification/authSlice';

export default function PositionedMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    navigate('login');
    handleClose();
    dispatch(logout());
  };

  return (
    <div>
      <Button id='demo-positioned-button' aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        <span className='link dropdown-menu'>
          <i className='icon-profileinfoicon' />
        </span>
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginTop: '50px',
          },
        }}
      >
        <Link to={{ pathname: '/profile' }}>
          <MenuItem className='menu-item' onClick={handleClose}>
            <img className='icon' src={MyProfile} alt='profile page' />
            <span>Mon Compte</span>
          </MenuItem>
        </Link>
        <MenuItem className='menu-item' onClick={logoutHandler}>
          <img className='icon' src={Logout} alt='log out' />
          <span>Me déconnecter</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
