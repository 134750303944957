import React from 'react';
import './Pagination.scss';

const Pagination = ({ data, currentPage, onPrevClick, onNextClick }) => {
  return (
    <div className='pagination'>
      <button className={`button beige-button prev ${currentPage === 1 && 'disabled'}`} onClick={onPrevClick}>
        Précedant
      </button>
      <span className='page-number'>page : {currentPage}</span>
      <button className={`button beige-button next ${data?.length !== 10 && 'disabled'}`} onClick={onNextClick}>
        Suivant
      </button>
    </div>
  );
};

export default Pagination;
