import React, { useEffect, useState } from 'react';
import Logo from 'assets/Logo.png';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '../Drawer/Drawer.js';
import MenuHome from '../MenuHome/MenuHome.js';
import MenuProfile from '../MenuProfile/MenuProfile.js';
import './Navbar.scss';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const path = useLocation();
  const [active, setActive] = useState('');

  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (path.pathname !== '/') {
      setActive('-blue');
    } else {
      setActive('');
    }
  }, [path]);

  return (
    <>
      {isLoggedIn ? (
        <div className={`logged-out-navbar-container${active}`}>
          <Link to={{ pathname: '/' }}>
            <img src={Logo} className='logo' alt='green city logo' />
          </Link>
          <div className='links-container-logged-in'>
            <div className='links-logged-in'>
              <MenuHome />
              <Link className='link' to={{ pathname: '/finance' }}>
                <span>DEMANDE DE FINANCEMENT</span>
              </Link>
              <Link className='link' to={{ pathname: '/proposition' }}>
                <span>PROPOSITION</span>
              </Link>
              <Link className='link' to={{ pathname: '/about-us' }}>
                <span>A PROPOS</span>
              </Link>
              <Link className='link' to={{ pathname: '/contact-us' }}>
                <span>CONTACTEZ-NOUS</span>
              </Link>
              <MenuProfile />
            </div>
          </div>
          <Drawer isLoggedIn={isLoggedIn} />
        </div>
      ) : (
        <div className={`logged-out-navbar-container${active}`}>
          <Link to={{ pathname: '/' }}>
            <img src={Logo} className='logo' alt='green city logo' />
          </Link>
          <div className='links-container'>
            <div className='links'>
              <div className='accueil link'>
                <MenuHome />
              </div>
              <Link className='link' to={{ pathname: '/about-us' }}>
                <span>A PROPOS</span>
              </Link>
              <Link className='link' to={{ pathname: '/contact-us' }}>
                <span>CONTACTEZ-NOUS</span>
              </Link>
            </div>
            <Link className='login-button button' to={{ pathname: '/login' }}>
              <span className='nav-button'>CONNEXION</span>
            </Link>
          </div>
          <Drawer isLoggedIn={isLoggedIn} />
        </div>
      )}
    </>
  );
};

export default Navbar;
