export const inputs = (user, customer) => {
  return [
    {
      required: true,
      defaultValue: customer?.name,
      name: 'client',
      label: 'Client',
      placeholder: 'Client',
    },
    {
      required: true,
      defaultValue: user?.email,
      name: 'mail',
      label: 'Adresse email',
      placeholder: 'Adresse email',
    },
    {
      required: true,
      defaultValue: user?.firstName,
      name: 'firstName',
      label: 'Prénom',
      placeholder: 'Prénom',
    },
    {
      required: false,
      defaultValue: user?.middleName,
      name: 'middleName',
      label: 'Deuxième prénom',
      placeholder: 'Deuxième prénom',
    },
    {
      required: true,
      defaultValue: user?.lastName,
      name: 'lastName',
      label: 'Nom de famille',
      placeholder: 'Nom de famille',
    },
    {
      required: false,
      defaultValue: user?.nameSuffix,
      name: 'nameSuffix',
      label: 'Suffixe de nom',
      placeholder: 'Suffixe de nom',
    },
    {
      required: false,
      defaultValue: user?.namePrefix,
      name: 'namePrefix',
      label: 'Préfixe de nom',
      placeholder: 'Préfixe de nom',
    },
  ];
};
