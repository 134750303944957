export const convertDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('fr-FR', options);

  return formattedDate;
};

export const makeAge = (date) => {
  const thisYear = new Date().getFullYear();
  const year = new Date(date).getFullYear();

  const age = thisYear - year;

  return `${age} ans`;
};
