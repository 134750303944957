import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box';

export const displayLoading = () => {
    const blocNumbers = Array.from({ length: 4 }, (_, id) => <Skeleton key={id} animation="wave" />)
    const lineNumbers = Array.from({ length: 4 }, (_, id) => <div key={id} className='wave-bloc'>{blocNumbers}</div>)

    return (
        <div className='about'>
            <Box>
                <Skeleton className='title-skeleton' animation="wave" />
                <Skeleton className='green-skeleton' animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <br />
                <div className='image-text-bloc'>
                    <div className='text-bloc'>
                        {lineNumbers}
                    </div>
                    <Skeleton className='image-skeleton' variant="rectangular" width={350} height={300} />
                </div>
            </Box>
        </div>
    )
}