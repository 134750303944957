import { API, API_HOST_FICODEV } from 'configs/api';
import React, { useEffect, useState } from 'react'
import axiosService from 'services/axiosServices';

const InternalStatus = ({ id }) => {
  const [intStatus, setintStatus] = useState("");

  const getInternalStatus = async () => {
    let data = await axiosService.get(API_HOST_FICODEV + API.request.requests + '/' + id + '/internal_status').then((res) => {
      setintStatus(res.data)
    })
    return data
  }

  /* eslint-disable */
  useEffect(() => {
    if (id) {
      getInternalStatus()
    }
  }, [id])
  /* eslint-enable */

  return (
    <td>{intStatus && intStatus.name}</td>
  )
}

export default InternalStatus