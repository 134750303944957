import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DropdownWithInput from 'components/DropdownWithInput';
import FilterList from 'components/FilterList/FilterList';

import { gettingAgencyList, gettingPropInternalStatusList } from 'redux/slices/filters/filtersAsyncThunk';
import { getPropositions } from 'redux/slices/propositions/propositionsAsyncThunk';

const PropositionsFilters = ({ id, currentPage }) => {
  const dispatch = useDispatch()

  const internalStatusList = useSelector((state) => state.filters.internalStatus);
  const agencyList = useSelector((state) => state.filters.agency);

  const [displayFilters, setDisplayFilters] = useState(true);
  const [internalStatus, setInternalStatus] = useState('');
  const [agency, setAgency] = useState('');
  const [filterId, setFilterId] = useState('');

  const toggleFilters = () => {
    setDisplayFilters(!displayFilters);
  };

  const resetFilters = () => {
    setInternalStatus('');
    setAgency('');
    setFilterId('')
  };

  const refreshFilter = () => {
    dispatch(
      getPropositions({
        id: id,
        page: currentPage,
        customer_status: internalStatus,
        customer: agency,
        filterId: filterId,
      })
    );
  }

  useEffect(() => {
    dispatch(gettingPropInternalStatusList());
  }, [dispatch])

  useEffect(() => {
    if (id) {
      dispatch(gettingAgencyList(id));
      dispatch(
        getPropositions({
          id: id,
          page: currentPage,
          customer_status: internalStatus,
          customer: agency,
          filterId: filterId,
        })
      );
    }
  }, [dispatch, id, currentPage, internalStatus, agency, filterId]);

  return (
    <>
      {displayFilters && (
        <div className='filters'>
          <DropdownWithInput onSearch={setFilterId} title='ID proposition' placeholder='ID de la propsition' />
          <FilterList setFilter={setInternalStatus} data={internalStatusList && internalStatusList.internalStatus} title='Status interne' />
          <FilterList setFilter={setAgency} data={agencyList && agencyList.agency} title='Agence' />
        </div>
      )}

      <div className='reset-refresh'>
        <button onClick={refreshFilter} className='action-btn'>
          <i className='icon-doublearrow icon' />
          <span>Actualiser</span>
        </button>
        <button onClick={resetFilters} className='action-btn'>
          <i className='icon-reload icon' />
          <span>Réinitialiser</span>
        </button>
        <button onClick={toggleFilters} className={`action-btn-filter action-btn ${displayFilters ? 'action-btn-filter-active' : null}`}>
          <i className='icon-filter icon' />
          <i className='icon-arrowdown filter-chevron' />
        </button>
      </div>
    </>
  );
};

export default PropositionsFilters;
