import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_HOST_FICODEV, API } from 'configs/api';
import axiosService from 'services/axiosServices';

export const gettingInternalStatusList = createAsyncThunk(
    'filters/getInternalStatusFilterList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.filters.internalStatus

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const gettingPropInternalStatusList = createAsyncThunk(
    'filters/getPropInternalStatusFilterList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.filters.propInternalStatus

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const gettingAgencyList = createAsyncThunk(
    'filters/getAgencyFilterList',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.filters.agency + (id ? '?users=' + id : '')

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);
