import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_HOST_FICODEV, API } from '../../../configs/api';
import { setToken } from '../../../services/authentificationServices';

import axiosService from 'services/axiosServices';

export const getUserProfil = createAsyncThunk('auth/userProfile', async (_, { fulfillWithValue, rejectWithValue }) => {
  const email = localStorage.getItem('email');
  const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${email}`;

  return axiosService
    .get(URL)
    .then((res) => {
      return fulfillWithValue(res.data[0]);
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const login = createAsyncThunk('auth/login', async (data, { fulfillWithValue, rejectWithValue }) => {
  const { email, password } = data;
  const URL = API_HOST_FICODEV + API.auth.login;

  return axios
    .post(URL, { meta: { email, password } })
    .then((res) => {
      let apiKey = res.data.meta.apiKey;

      setToken(apiKey);
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('email', email);

      return fulfillWithValue({ apiKey, email });
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const registerUser = createAsyncThunk(API.auth.user, async (dataObj, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST_FICODEV + API.auth.user;
  const data = {
    confirmed: true,
    email: dataObj.email,
    firstName: dataObj.firstName,
    lastName: dataObj.lastName,
    enabled: true,
    username: dataObj.email,
    password: dataObj.password,
    s_email_agent: 'cd@agence-tbd.com',
    roles: [3],
    customer: 1,
  };
  return axiosService
    .post(URL, data)
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const getUserAdresses = createAsyncThunk('auth/userAdresses', async (id, { fulfillWithValue, rejectWithValue }) => {
  const URL = `${API_HOST_FICODEV}${API.auth.userAdresses}?fields[customer_users]=${id}`;

  return axiosService
    .get(URL)
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});

export const editingProfile = createAsyncThunk('auth/editProfile', async (data, { fulfillWithValue, rejectWithValue }) => {
  const URL = `${API_HOST_FICODEV}${API.auth.user}/${data.id}`;

  return axiosService
    .patch(URL, data.data)
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});
