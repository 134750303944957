import React, { useEffect, useState } from 'react';
import './Register.scss';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from 'redux/slices/authentification/authAsyncThunk';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, data } = useSelector((state) => state.auth.register);
  const { register } = useForm();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [match, setMatch] = useState(true);

  const handlePassword1Change = (event) => {
    setPassword1(event.target.value);
    setMatch(true);
  };

  const handlePassword2Change = (event) => {
    setPassword2(event.target.value);
    setMatch(true);
  };
  useEffect(() => {
    if (data) {
      navigate('/login'); // redirect to login if success
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
  const onSubmit = (event) => {
    event.preventDefault();
    if (password1 === password2) {
      setMatch(true);
      let data = {
        firstName: event.target.firstName?.value,
        lastName: event.target.lastName?.value,
        email: event.target.email?.value,
        password: event.target.password?.value,
      };
      dispatch(registerUser(data));
    } else {
      setMatch(false);
    }
  };

  return (
    <form onSubmit={onSubmit} className='register-container'>
      <div className='flex-column'>
        <h1>S'INSCRIRE</h1>
        <span className='register-directions'>Veuillez vous enregistrer si vous n'avez pas de compte. Un vendeur vous contactera rapidement</span>
        <div className='input'>
          <label>Prénom</label>
          <input {...register('firstName')} name='firstName' required={true} placeholder='John' />
        </div>
        <div className='input'>
          <label>Nom</label>
          <input {...register('lastName')} name='lastName' required={true} placeholder='Doe' />
        </div>
        <div className='input'>
          <label>Adresse e-mail</label>
          <input {...register('email')} name='email' required={true} type='email' placeholder='nom@exemple.com' />
          {error?.detail === 'This email is already used.' && <p className='error-message'>Cet email existe déjà.</p>}
        </div>
        <div className='input'>
          <label>Mot de passe</label>
          <input {...register('password')} name='password' required={true} type='password' value={password1} onChange={handlePassword1Change} placeholder='Mot de passe' />
        </div>
        <div className='input'>
          <label>Ressaisissez votre mot de passe</label>
          <input {...register('confirmPassword')} name='confirmPassword' required={true} type='password' value={password2} onChange={handlePassword2Change} placeholder='Ressaisissez votre mot de passe' />
          {!match && <p className='error-message'>Mots de passe pas identiques.</p>}
          {error?.detail === 'The password must be at least 8 characters long and include an upper case letter and a number' && <p className='error-message'>Le mot de passe doit comporter au moins 8 caractères et inclure une lettre majuscule et un chiffre.</p>}
        </div>
        <button type='submit' className={`beige-button button`}>
          {isLoading && <CircularProgress />}
          <span>Créer un compte</span>
        </button>
        <span className='got-account'>
          Vous avez déjà un compte?
          <Link to={{ pathname: '/login' }}>
            <span className='dark-blue accept'> Connectez-vous</span>
          </Link>
        </span>
      </div>
    </form>
  );
};

export default Register;
