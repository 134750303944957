import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCustomer } from 'redux/slices/costumers/customersAsyncThunk';

import { convertDate, makeAge } from 'utils/dataConversion';

const UserInformation = ({ user }) => {
  const dispatch = useDispatch();

  const { customer } = useSelector((state) => state.customers);

  let status = [user?.enabled ? 'Activé' : 'Désactivé', user?.isGuest ? 'Non Confirmé' : 'Confirmé'];

  useEffect(() => {
    if (user?.customer) dispatch(getCustomer(user?.customer));
  }, [dispatch, user]);

  return (
    <>
      <div className='info-edit-profile'>
        <i className='icon-profileinfoicon'></i>
        <span className='sub-title'>INFORMATIONS COMPTE CLIENT</span>
        <Link to={{ pathname: '/edit-profile' }}>
          <i className='icon-edit'></i>
        </Link>
      </div>
      <div className='separator' />
      <div className='info'>
        <div className='first-col'>
          <span className='sub-title user-params'>Client :</span>
          <span className='sub-title user-params'>Nom :</span>
          <span className='sub-title user-params'>Date de naissance :</span>
          <span className='sub-title user-params'>Email :</span>
          <span className='sub-title user-params'>Statut :</span>
        </div>
        <div className='second-col'>
          {customer && <span className='user-params'>{customer?.name}</span>}
          <span className='user-params'>
            {user?.namePrefix} {user?.firstName} {user?.middleName} {user?.lastName} {user?.nameSuffix}
          </span>
          <span className='user-params'>
            {convertDate(user?.birthday)} {`(${makeAge(user?.birthday)})`}
          </span>
          <span onClick={() => (window.location = `mailto:${user?.email}`)} className='user-params email'>
            {user?.email}
          </span>
          {status?.map((stat, index) => {
            return (
              <div key={index} className='status'>
                <i className='icon-greencheck'></i>
                <span>{stat}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default UserInformation;
