import { API, API_HOST_FICODEV } from 'configs/api';
import React, { useEffect, useState } from 'react'
import axiosService from 'services/axiosServices'

const Customer = ({ id, type }) => {

  const [customer, setCustomer] = useState('')

  useEffect(() => {
    const getStatus = async () => {
      let data = await axiosService.get(API_HOST_FICODEV + API.request.customers + '/' + id).then((res) => {
        setCustomer(res.data);
      });
      return data;
    };
    getStatus();
  }, [id]);

  return (
    type === 'proposition' ?
      <td>
        {customer && customer?.name}
      </td> :
      <div>
        {customer && customer?.name}
      </div>
  )
}

export default Customer
