import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box';

export const displayLoading = () => {

    const blocNumbers = Array.from({ length: 7 }, (_, id) => <Skeleton key={id} animation="wave" />)
    const lineNumbers = Array.from({ length: 6 }, (_, id) => <div key={id} className='wave-bloc'>{blocNumbers}</div>)

    return (
        <div className='about'>
            <Box>
                <Skeleton className='title-skeleton' animation="wave" />
                {lineNumbers}
            </Box>
        </div>
    )
}