import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { publicRoutes, routes } from 'routes/routes';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

import './fontello/css/fontello.css';
import './App.scss';
import { getUserProfil } from 'redux/slices/authentification/authAsyncThunk';

function App() {
  const dispatch = useDispatch();
  const path = useLocation();

  const [isActive, setIsActive] = useState(false);

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const userEmail = localStorage.getItem('email');
  const sidebarPathTab = ['/profile', '/finance', '/proposition', '/edit-profile', '/finance/add-request', '/finance/edit-request'];

  useEffect(() => {
    if (!document.cookie) {
      window.axeptioSettings = {
        clientId: '63e5efb043f413d07c5a754b',
        cookiesVersion: 'green city-fr',
      };
      const script = document.createElement('script');
      script.async = true;
      script.src = '//static.axept.io/sdk-slim.js';
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (userEmail && !user) dispatch(getUserProfil(userEmail));
  }, [dispatch, userEmail, user]);

  useEffect(() => {
    sidebarPathTab.includes(path.pathname) ? setIsActive(true) : setIsActive(false);
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='app'>
      <Navbar />
      {isActive && <Sidebar />}
      <div className={`app-container-logged-id-${isActive}`}>
        <Routes>
          {publicRoutes.map((route, index) => {
            return <Route key={index} path={route.path} element={route.component} />;
          })}

          {routes.map((route, index) => {
            return <Route key={index} path={route.path} element={route.isPrivate && !isLoggedIn ? <Navigate to='/login' /> : !route.isPrivate && isLoggedIn ? <Navigate to='/' /> : route.component} />;
          })}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
