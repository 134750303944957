import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_HOST_FICODEV, API } from '../../../configs/api';

import axiosService from 'services/axiosServices';

export const getCustomer = createAsyncThunk('customers/oneCustomer', async (id, { fulfillWithValue, rejectWithValue }) => {
  const URL = `${API_HOST_FICODEV}${API.customers.customers}/${id}`;

  return axiosService
    .get(URL)
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => {
      return rejectWithValue(err);
    });
});
