import { useState } from 'react';
import Menu from '@mui/material/Menu';
import './DropdownWithInput.scss';

export default function DropdownWithInput({ title, placeholder, onSearch }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (value) => {
    onSearch(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <div id='demo-positioned-button' aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick} className='one-filter'>
        <span>{title}</span>
        <i className='icon-arrowdown'></i>
      </div>
      <Menu
        className='menu'
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginTop: '8px',
            borderRadius: '4px',
          },
        }}
      >
        <div className='agent-menu-container'>
          <input placeholder={placeholder} onChange={(e) => setValue(e.target.value)} />
          <button onClick={() => handleSearch(value)} className='beige-button button'>
            Rechercher
          </button>
        </div>
      </Menu>
    </div>
  );
}
