import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/slices/authentification/authSlice';

export default function TemporaryDrawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    right: false,
  });

  const sharedLinks = [
    {
      text: 'ACCUEIL',
      link: '/',
    },
    {
      text: 'MENTION LEGALE',
      link: '/legalNotice',
    },
    {
      text: 'COOKIES',
      link: '/cookies',
    },
    {
      text: 'A PROPOS',
      link: '/about-us',
    },
    {
      text: 'CONTACTEZ-NOUS',
      link: '/contact-us',
    },
  ];

  const loggedOutLinks = [
    {
      text: 'CONNEXION',
      link: '/login',
    },
  ];

  const loggedInLinksList = [
    {
      text: 'DEMANDE DE FINANCEMENT',
      link: '/finance',
    },
    {
      text: 'PROPOSITION',
      link: '/proposition',
    },
    {
      text: 'PROFIL',
      link: '/profile',
    },
  ];

  const makeLinks = () => {
    if (isLoggedIn) {
      sharedLinks.splice(1, 0, ...loggedInLinksList);
      return sharedLinks;
    } else {
      let tab = [...sharedLinks, ...loggedOutLinks];
      return tab;
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    navigate('login');
    dispatch(logout());
  };

  const list = (anchor) => (
    <Box role='presentation' onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List className='link-list'>
        {makeLinks().map((link, index) => (
          <Link key={index} to={{ pathname: `${link.link}` }}>
            <ListItem>
              <ListItemButton>
                <ListItemText>
                  {link.text}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
        {isLoggedIn && (
          <ListItem key='logout'>
            <ListItemButton>
              <ListItemText>
                <span className='logout' onClick={logoutHandler}>
                  DECONNEXION
                </span>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return isLoggedIn ? (
    <div className='drawer-bloc-logged-in'>
      <React.Fragment key={'right'}>
        <Button onClick={toggleDrawer('right', true)}>
          <i className='menu-button icon-menuburger'></i>
        </Button>
        <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  ) : (
    <div className='drawer-bloc'>
      <React.Fragment key={'right'}>
        <Button onClick={toggleDrawer('right', true)}>
          <i className='menu-button icon-menuburger'></i>
        </Button>
        <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
