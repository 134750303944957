import axios from 'axios';
import { UsernameToken } from 'wsse';
import dayjs from 'dayjs';

const axiosService = {
  get: function (url, config) {
    return axios.get(url, generateHeaders(config));
  },
  post: function (url, data, config) {
    return axios.post(url, data, generateHeaders(config));
  },
  put: function (url, data, config) {
    return axios.put(url, data, generateHeaders(config));
  },
  delete: function (url, config) {
    return axios.delete(url, generateHeaders(config));
  },
  patch: function (url, data, config) {
    return axios.patch(url, data, generateHeaders(config));
  }
};

function generateHeaders(config) {
  const token = new UsernameToken({
    username: 'abenthabet',
    password: '10f21344de6081b6a8f22e01824d78015dfadcab',
    created: dayjs().format()
  });
  const head = token.toString({ nonceBase64: true });

  if (!config) {
    config = {};
  }

  if (!config.headers) {
    config.headers = {};
  }

  config.headers.Authorization = 'WSSE profile="UsernameToken"';
  config.headers['X-WSSE'] = head;

  return config;
}

export default axiosService;
