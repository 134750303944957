import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs, CircularProgress, Typography } from '@mui/material';
import AgentDropDown from 'components/DropdownWithInput';
import FilterList from 'components/FilterList/FilterList';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table/Table';
import { Link } from 'react-router-dom';
import { gettingInternalStatusList } from 'redux/slices/filters/filtersAsyncThunk';
import { gettingAllRequests } from 'redux/slices/financial-request/financialRequestAsyncThunk';
import './Finance.scss';

const Finance = () => {
  const dispatch = useDispatch();

  const { requests, isLoading } = useSelector((state) => state.requests.requests);
  const internalStatusList = useSelector((state) => state.filters.internalStatus);
  const user = useSelector((state) => state.auth.user);

  const [displayFilters, setDisplayFilters] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [internalStatus, setInternalStatus] = useState('');
  const [agentFilterValue, setAgentFilterValue] = useState('');
  const [financeId, setFinanceId] = useState('');

  const toggleFilters = () => {
    setDisplayFilters(!displayFilters);
  };

  const resetFilters = () => {
    setInternalStatus('');
    setAgentFilterValue('');
    setFinanceId('')
  };

  const refreshtable = () => {
    dispatch(
      gettingAllRequests({
        id: user?.id,
        page: currentPage,
        internalStatus: internalStatus,
        s_email_agent: agentFilterValue,
        financeId: financeId
      })
    );
  };

  const onNextClick = () => {
    let a = currentPage;
    if (requests?.length === 10) {
      setCurrentPage(a + 1);
    }
  };

  const onPrevClick = () => {
    let a = currentPage;
    if (currentPage !== 1) {
      setCurrentPage(a - 1);
    }
  };

  useEffect(() => {
    dispatch(gettingInternalStatusList());
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(1)
  }, [internalStatus, agentFilterValue])

  useEffect(() => {
    if (user) {
      dispatch(
        gettingAllRequests({
          id: user?.id,
          page: currentPage,
          internalStatus: internalStatus,
          s_email_agent: agentFilterValue,
          financeId: financeId
        })
      );
    }
  }, [dispatch, user, currentPage, internalStatus, financeId, agentFilterValue]);

  return (
    <div className='finance'>
      <div className={`loader-${isLoading}`}>
        <CircularProgress />
      </div>
      <div className='filter-new-prop-bloc'>
        <div>
          <h1>DEMANDES DE FINANCEMENT</h1>
          <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
            <Link className='bread-link' to='/'>
              Accueil
            </Link>
            <Typography color='text.primary'>Demandes de financement</Typography>
          </Breadcrumbs>
        </div>
        <Link className='beige-button button' to={{ pathname: '/finance/add-request' }}>
          <i className='icon-plus icon'></i>
          <span>Nouvelle demande</span>
        </Link>
      </div>
      {displayFilters && (
        <div className='filters'>
          <AgentDropDown onSearch={setFinanceId} placeholder='ID demande' title='Filtrer par ID de la demande' />
          <FilterList setFilter={setInternalStatus} data={internalStatusList && internalStatusList.internalStatus} title='Status interne' />
          <AgentDropDown onSearch={setAgentFilterValue} placeholder='Agent' title='Filtrer par agent' />
        </div>
      )}
      <div className='reset-refresh'>
        <button onClick={refreshtable} className='action-btn'>
          <i className='icon-doublearrow icon'></i>
          <span>Actualiser</span>
        </button>
        <button onClick={resetFilters} className='action-btn'>
          <i className='icon-reload icon'></i>
          <span>Réinitialiser</span>
        </button>
        <button onClick={toggleFilters} className={`action-btn-filter action-btn  ${displayFilters ? 'action-btn-filter-active' : null}`}>
          <i className='icon-filter icon'></i>
          <i className='icon-arrowdown filter-chevron'></i>
        </button>
      </div>
      {requests?.length === 0 || isLoading ? (
        <div className='no-data'>
          <span>Aucun enregistrement trouvé</span>
        </div>
      ) : (
        <div>
          <Table type='finance' requests={requests} />
          <div className='pagination'>
            <div />
            <Pagination data={requests} currentPage={currentPage} onNextClick={onNextClick} onPrevClick={onPrevClick} />
          </div>
        </div>
      )}
      <div className='legend'>
        <span className='title'>Légende</span>
        <ul className='legend-list'>
          <li>PDVF : Prix de vente à financer, montant de votre demande de financement</li>
          <li>NTDFC : nombre total de fois ou vous avez été contacté par votre courtier</li>
          <li>DEHDDC : date de votre dernier contact avec votre courtier</li>
        </ul>
      </div>
    </div>
  );
};

export default Finance;
