import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserAdresses } from 'redux/slices/authentification/authAsyncThunk';

import UserInformation from './components/UserInformation';
import AdressesTableContainer from './components/adresses';

import './Profile.scss';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Profile = () => {
  const dispatch = useDispatch();

  const { user, isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserAdresses(user?.id));
    }
  }, [dispatch, user?.id]);

  return (
    <div className='profile'>
      <h1>Mon profil</h1>
      <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
        <Link className='bread-link' to='/'>
          Accueil
        </Link>
        <Typography color='text.primary'>Mon profil</Typography>
      </Breadcrumbs>
      <UserInformation user={user} />
      <AdressesTableContainer isLoading={isLoading} addresses={user?.addresses} />
    </div>
  );
};

export default Profile;
