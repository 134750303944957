import { configureStore } from '@reduxjs/toolkit';

import auth from './slices/authentification/authSlice';
import customers from './slices/costumers/customersSlice';
import dropdowns from './slices/dropdown-finance/dropdownFinanceSlice';
import filters from './slices/filters/filtersSlice';
import propositions from './slices/propositions/propositionsSlice';
import requests from './slices/financial-request/financialRequestSlice';
import staticPages from './slices/static-pages/staticPagesSlice';

// The reducers should be listed alphabetically to make it easier to find them.
// Please keep this order when adding or removing reducers.
export const store = configureStore({
  reducer: {
    auth,
    customers,
    dropdowns,
    filters,
    propositions,
    requests,
    staticPages,
  },
});

export default store;
