import React from 'react';
import './Sidebar.scss';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const path = useLocation();

  return path.pathname === '/profile' || path.pathname === '/edit-profile' ? (
    <div className='sidebar'>
      <span className='my-account'>Mon compte</span>
      <Link to={{ pathname: '/profile' }}>
        <div className={'beige sidebar-link'}>
          <i className='icon icon-file' />
          <span>Mes données personnelles</span>
        </div>
      </Link>
    </div>
  ) : (
    <div className='sidebar'>
      <span className='my-account'>Mon compte</span>
      <Link to={{ pathname: '/finance' }}>
        <div className={`${path.pathname.includes('/finance') ? 'beige' : 'gray'} sidebar-link`}>
          <i className='icon icon-file' />
          <span>Demandes de financement</span>
        </div>
      </Link>
      <Link to={{ pathname: '/proposition' }}>
        <div className={`${path.pathname.includes('/proposition') ? 'beige' : 'gray'} sidebar-link`}>
          <i className='icon icon-receivemoney-1' />
          <span className='proposition'>Propositions</span>
        </div>
      </Link>
    </div>
  );
};

export default Sidebar;
