import { createSlice } from '@reduxjs/toolkit';
import {
  contactingGreenCity,
  gettingAboutUsPage,
  gettingCookiesPage,
  gettingLegalNoticePage,
} from './staticPagesAsyncThunk';

const initialState = {
  about: {
    data: null,
    isLoading: false,
    error: null,
  },
  legal: {
    data: null,
    isLoading: false,
    error: null,
  },
  cookies: {
    data: null,
    isLoading: false,
    error: null,
  },
  contact: {
    data: null,
    isLoading: false,
    error: null,
  },
};

export const staticPagesSlice = createSlice({
  name: 'staticPages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // about
      .addCase(gettingAboutUsPage.pending, (state) => {
        state.about.isLoading = true;
      })
      .addCase(gettingAboutUsPage.fulfilled, (state, action) => {
        state.about.isLoading = false;
        state.about.error = null;
        state.about.data = action.payload;
      })
      .addCase(gettingAboutUsPage.rejected, (state, action) => {
        state.about.isLoading = false;
        state.about.error = action.error.message;
      })

      // legal notice
      .addCase(gettingLegalNoticePage.pending, (state) => {
        state.legal.isLoading = true;
      })
      .addCase(gettingLegalNoticePage.fulfilled, (state, action) => {
        state.legal.isLoading = false;
        state.legal.error = null;
        state.legal.data = action.payload;
      })
      .addCase(gettingLegalNoticePage.rejected, (state, action) => {
        state.legal.isLoading = false;
        state.legal.error = action.error.message;
      })

      // cookies
      .addCase(gettingCookiesPage.pending, (state) => {
        state.cookies.isLoading = true;
      })
      .addCase(gettingCookiesPage.fulfilled, (state, action) => {
        state.cookies.isLoading = false;
        state.cookies.error = null;
        state.cookies.data = action.payload;
      })
      .addCase(gettingCookiesPage.rejected, (state, action) => {
        state.cookies.isLoading = false;
        state.cookies.error = action.error.message;
      })

      // contact
      .addCase(contactingGreenCity.pending, (state) => {
        state.contact.isLoading = true;
      })
      .addCase(contactingGreenCity.fulfilled, (state, action) => {
        state.contact.isLoading = false;
        state.contact.error = null;
        state.contact.data = action.payload;
      })
      .addCase(contactingGreenCity.rejected, (state, action) => {
        state.contact.isLoading = false;
        state.contact.error = action.error.message;
      });
  },
});

export default staticPagesSlice.reducer;
