import AboutUs from 'pages/AboutUs/AboutUs';
import AddFinanceRequest from 'pages/AddFinanceRequest/AddFinanceRequest';
import ContactUs from 'pages/ContactUs/ContactUs';
import CookiesPage from 'pages/CookiesPage/CookiesPage';
import EditProfile from 'pages/EditProfile/EditProfile';
import Finance from 'pages/Finance/Finance';
import ForgotPass from 'pages/ForgotPass/ForgotPass';
import Home from 'pages/Home/Home';
import LegalNotice from 'pages/LegalNotice/LegalNotice';
import Login from 'pages/Login/Login';
import Profile from 'pages/Profile';
import Proposition from 'pages/Proposition';
import PropositionDetail from 'pages/PropositionDetail';
import Register from 'pages/Register/Register';

// routes accessible to all users
export const publicRoutes = [
  {
    path: '/',
    component: <Home />,
  },
  {
    path: '/about-us',
    component: <AboutUs />,
  },
  {
    path: '/legalNotice',
    component: <LegalNotice />,
  },
  {
    path: '/cookies',
    component: <CookiesPage />,
  },
  {
    path: '/contact-us',
    component: <ContactUs />,
  },
];

// routes that require authentication are marked as private and should only be accessed by authenticated users.
export const routes = [
  {
    path: '/register',
    component: <Register />,
    isPrivate: false,
  },
  {
    path: '/login',
    component: <Login />,
    isPrivate: false,
  },
  {
    path: '/forgot',
    component: <ForgotPass />,
    isPrivate: false,
  },
  {
    path: '/finance',
    component: <Finance />,
    isPrivate: true,
  },
  {
    path: '/profile',
    component: <Profile />,
    isPrivate: true,
  },
  {
    path: '/edit-profile',
    component: <EditProfile />,
    isPrivate: true,
  },
  {
    path: '/finance/add-request',
    component: <AddFinanceRequest />,
    isPrivate: true,
  },
  {
    path: '/finance/edit-request/:id',
    component: <AddFinanceRequest />,
    isPrivate: true,
  },
  {
    path: '/finance/request/:id',
    component: <AddFinanceRequest />,
    isPrivate: true,
  },
  {
    path: '/proposition',
    component: <Proposition />,
    isPrivate: true,
  },
  {
    path: '/proposition/:id',
    component: <PropositionDetail />,
    isPrivate: true,
  },
];
