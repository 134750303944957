/* set token */
export const setToken = (token) => {
  localStorage.setItem('token', token);
};

/* get token */
export const getToken = () => {
  const token = localStorage.getItem('token') || null;
  return token;
};
