import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import './Modal.scss';

const ErrorModal = ({ error }) => {
  const [open, setOpen] = useState(!!error);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (error) setOpen(true);
  }, [error]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box className='error-modal'>
        <i className='icon-cross' onClick={handleClose}></i>
        <Typography id='modal-modal-title' variant='h6' component='h2' sx={{ mt: 1 }}>
          Oups, quelque chose s'est mal passé
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          Nous sommes désolés, mais une erreur s'est produite lors du traitement de votre demande. Veuillez réessayer plus tard ou contacter notre support technique si le problème persiste.
        </Typography>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
