import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { displayLoading } from './CookiesLoader';
import { gettingCookiesPage } from 'redux/slices/static-pages/staticPagesAsyncThunk';

import './Cookies.scss';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const CookiesPage = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.staticPages.cookies);

  useEffect(() => {
    dispatch(gettingCookiesPage());
  }, [dispatch]);

  const displayCookiesContent = () => {
    if (data && !isLoading) {
      const htmlString = data;
      const reactElement = parse(htmlString);
      return reactElement;
    }
  };

  return isLoading ? (
    displayLoading()
  ) : (
    <div className='cookies'>
      <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
        <Link className='bread-link' to='/'>
          Accueil
        </Link>
        <Typography color='text.primary'>Cookies</Typography>
      </Breadcrumbs>
      {displayCookiesContent()}
    </div>
  );
};

export default CookiesPage;
