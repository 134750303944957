export const cards = [
  {
    code: (
      <div className='d-flex card'>
        <i className='image-roadmap icon-file' />
        <div className='card-texts'>
          <span className='beige bold text'>Vous remplissez le formulaire de demande de financement</span>
          <span className='text'>
            <div className='dark-blue'>
              Pensez à rassembler les informations concernant <span className='bold'>vos revenus, vos charges et votre patrimoine.</span>
            </div>
          </span>
        </div>
      </div>
    ),
  },
  {
    code: (
      <div className='d-flex card'>
        <i className='image-roadmap icon-thumb' />
        <div className='card-texts'>
          <span className='beige bold text'>Vous obtenez un avis de faisabilité</span>
          <span className='text'>
            <div className='dark-blue'>
              <span className='bold'>Echangez en ligne</span> avec votre expert crédit.
            </div>
          </span>
        </div>
      </div>
    ),
  },
  {
    code: (
      <div className='d-flex card'>
        <i className='image-roadmap icon-truckfile' />
        <div className='card-texts'>
          <span className='beige bold text'>Vous transmettez votre dossier complet à GREENCITY</span>
          <span className='text'>
            <div className='dark-blue'>
              <span className='bold'>Signez électroniquement </span>votre mandat de financement et<span className='bold'> téléchargez vos pièces justificatives.</span>
            </div>
          </span>
        </div>
      </div>
    ),
  },
  {
    code: (
      <div className='d-flex card'>
        <i className='image-roadmap icon-search' />
        <div className='card-texts'>
          <span className='beige bold text'>Le partenaire banquaire étudie votre dossier</span>
          <span className='text'>
            <div className='dark-blue'>
              Si votre dossier est accepté, <span className='bold'>GREENCITY vous transmet votre proposition de prêt</span>
            </div>
          </span>
        </div>
      </div>
    ),
  },
  {
    code: (
      <div className='d-flex card'>
        <i className='image-roadmap icon-sign' />
        <div className='card-texts'>
          <span className='beige bold text'>Recevez et signez votre offre de prêt à votre domicile</span>
          <span className='text'>
            <div className='dark-blue'>
              Conformément à la règlementation, vous ne pourrez signer l'offre de prêt qu'après un<span className='bold'> délai de réflexion de 10 jours. </span>Celle-ci est valable 30 jours.
            </div>
          </span>
        </div>
      </div>
    ),
  },
];
