import { createSlice } from '@reduxjs/toolkit';
import { editingProfile, getUserAdresses, getUserProfil, login, registerUser } from './authAsyncThunk';

const initialState = {
  isLoggedIn: localStorage.getItem('token'),
  isLoading: false,
  error: null,
  user: null,
  editSuccess: false,
  register: {
    isLoading: false,
    error: null,
    data: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('email');
      state.isLoggedIn = null;
    },
    emptyingEditSuccess: (state) => {
      state.editSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.isLoggedIn = action.payload.apiKey;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //get user data
      .addCase(getUserProfil.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfil.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(getUserProfil.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //get user adresses
      .addCase(getUserAdresses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserAdresses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.user = { ...state.user, adresses: action.payload };
      })
      .addCase(getUserAdresses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //Register
      .addCase(registerUser.pending, (state) => {
        state.register.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.register.isLoading = false;
        state.register.data = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.register.isLoading = false;
        state.register.error = action.payload;
      })

      //edit profile
      .addCase(editingProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editingProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.editSuccess = true;
      })
      .addCase(editingProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, emptyingEditSuccess } = authSlice.actions;

export default authSlice.reducer;
