const API_HOST = 'https://api.green-city.prod-projet.com/';
const API_HOST_FICODEV = 'https://preprod-web.ficodev.comaite.com';

const API = {
  auth: {
    login: '/api/login',
    user: '/admin/api/customer_users',
    userAdresses: '/admin/api/customer_user_addresses',
  },
  static: {
    about: 'items/page/1',
    legal: 'items/page/2',
    cookies: 'items/page/3',
    contact: 'items/contact',
  },
  request: {
    requests: '/admin/api/requests',
    customers: '/admin/api/customers',
  },
  dropdown: {
    goodsType: '/admin/api/requeststypebiend60e813es',
    buyMode: '/admin/api/requestsmodeachat1f10f2a2s',
    buyModality: '/admin/api/requestsmodaliteachatfa695442s',
    goodsUse: '/admin/api/requestsusagebien25628e96s',
    advanceAqui: '/admin/api/requestsetatavancement3650e254s',
    durationLoan: '/admin/api/requestsdureecreditsouhaite7928f01s',
    proSituation: '/admin/api/requestssituationpro3c4f720bs',
  },
  propositions: {
    list: '/admin/api/ficodevquotes',
  },
  filters: {
    internalStatus: '/admin/api/rfpinternalstatuses',
    propInternalStatus: '/admin/api/quoteinternalstatuses',
    agency: '/admin/api/customers'
  },
  customers: {
    customers: '/admin/api/customers',
  },
};

export { API_HOST, API, API_HOST_FICODEV };
