import { createSlice } from '@reduxjs/toolkit';
import { getPropositions, getOneProposition, getOnePropositionCustomer, getOnePropositionAssignee, getOnePropositionCompany, getOnePropositionFileOne, getOnePropositionFileThree, getOnePropositionFileTwo } from './propositionsAsyncThunk';

const initialState = {
  data: [],
  oneProp: null,
  customer: null,
  assignee: null,
  company: null,
  isLoading: false,
  error: null,
  applyedFilters: {},
  fileOne: null,
  fileTwo: null,
  fileThree: null
};

export const propositionsSlice = createSlice({
  name: 'propositions',
  initialState,
  reducers: {
    getFilterValue: (state, { payload }) => {
      state.applyedFilters = { ...state.applyedFilters, ...payload };
    },
    emptyingOneProp: (state) => {
      state.isLoading = false;
      state.oneProp = null;
      state.customer = null;
      state.company = null;
      state.assignee = null;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // get propositions
      .addCase(getPropositions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPropositions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getPropositions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOneProposition.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneProposition.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.oneProp = action.payload;
      })
      .addCase(getOneProposition.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOnePropositionCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePropositionCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.customer = action.payload;
      })
      .addCase(getOnePropositionCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOnePropositionAssignee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePropositionAssignee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.assignee = action.payload;
      })
      .addCase(getOnePropositionAssignee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOnePropositionCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePropositionCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.company = action.payload;
      })
      .addCase(getOnePropositionCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOnePropositionFileOne.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePropositionFileOne.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fileOne = action.payload;
      })
      .addCase(getOnePropositionFileOne.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOnePropositionFileTwo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePropositionFileTwo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fileTwo = action.payload;
      })
      .addCase(getOnePropositionFileTwo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getOnePropositionFileThree.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOnePropositionFileThree.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fileThree = action.payload;
      })
      .addCase(getOnePropositionFileThree.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
  },
});

export const { getFilterValue, emptyingOneProp } = propositionsSlice.actions;

export default propositionsSlice.reducer;
