import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Table from 'components/Table/Table';
import Pagination from 'components/Pagination/Pagination';

import { getPropositions } from 'redux/slices/propositions/propositionsAsyncThunk';
import { CircularProgress } from '@mui/material';

const PropositionsTable = ({ setCurrentPage, currentPage, data, isLoading, loggedUserId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedUserId) dispatch(getPropositions({ id: loggedUserId, page: currentPage }));
  }, [dispatch, loggedUserId, currentPage]);

  const onNextClick = () => {
    let a = currentPage;
    if (data?.length === 10) {
      setCurrentPage(a + 1);
    }
  };

  const onPrevClick = () => {
    let a = currentPage;
    if (currentPage !== 1) {
      setCurrentPage(a - 1);
    }
  };

  return (
    <>
      <div className={`loader-${isLoading}`}>
        <CircularProgress />
      </div>
      {data?.length === 0 ? (
        <div className='no-data'>
          <span>Aucun enregistrement trouvé</span>
        </div>
      ) : (
        <Table type='proposition' requests={data} />
      )}
      <div className='pagination'>
        <div />
        <Pagination data={data} currentPage={currentPage} onNextClick={() => onNextClick()} onPrevClick={() => onPrevClick()} />
      </div>
    </>
  );
};

export default PropositionsTable;
