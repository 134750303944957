import React, { useRef } from 'react';
import Hero from 'assets/hero-bg.png';
import { Link } from 'react-router-dom';
import RoadMap from 'components/RoadMap/RoadMap';
import Arrow from 'assets/arrow.png';
import Dot from 'assets/dot.png';
import './Home.scss';
import { useSelector } from 'react-redux';

const Home = () => {
  const ref = useRef(null);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <img className='hero-bg' src={Hero} alt='hero-background' />
      <div className='home-screen-titles'>
        <h1 className='h1'>La vision GreenCity</h1>
        <span className='h2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas maximus lectus eget consequat ultrices. Ut volutpat quam non tortor posuere condimentum.</span>

        {!isLoggedIn && (
          <div className='home-buttons'>
            <Link className='beige-button button register' to={{ pathname: '/register' }}>
              S'INSCRIRE
            </Link>
            <Link className='login-button button' to={{ pathname: '/login' }}>
              CONNEXION
            </Link>
          </div>
        )}

        <div onClick={handleClick} className='arrow-container'>
          <img className='arrow' src={Arrow} alt='arrow' />
          <img src={Dot} alt='dot' />
        </div>
      </div>
      <RoadMap forwardRef={ref} />
    </div>
  );
};

export default Home;
