import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

export default function PositionedMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <span className='link dropdown-menu'>ACCUEIL<i className='chevron-accueil icon-chevrondown' ></i></span>

            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        marginTop: '50px'
                    }
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Link to={{ pathname: '/legalNotice' }}>Mention légales</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={{ pathname: '/cookies' }}>Cookies</Link>
                </MenuItem>
            </Menu>
        </div>
    );
}
