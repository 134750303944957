import Input from 'components/Input/Input';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import './EditProfile.scss';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { editingProfile } from 'redux/slices/authentification/authAsyncThunk';
import { emptyingEditSuccess } from 'redux/slices/authentification/authSlice';
import { inputs } from './profileInputs';
import { getCustomer } from 'redux/slices/costumers/customersAsyncThunk';

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, editSuccess } = useSelector((state) => state.auth);
  const { customer } = useSelector((state) => state.customers);
  const [value, setValue] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    editProfile({ ...data, birthday: value });
  };

  const editProfile = (data) => {
    dispatch(
      editingProfile({
        id: user.id,
        data: {
          email: data.mail,
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          nameSuffix: data.nameSuffix,
          namePrefix: data.namePrefix,
          username: data.mail,
          birthday: data.birthday,
        },
      })
    );
  };

  useEffect(() => {
    setValue(user?.birthday);
  }, [user]);

  useEffect(() => {
    if (user) dispatch(getCustomer(user.customer));
  }, [user, dispatch]);

  useEffect(() => {
    if (editSuccess) {
      navigate('/profile');
    }
  }, [editSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(emptyingEditSuccess());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='edit-profile'>
      <h1>MODIFIER LE PROFIL</h1>
      <div className='profile-info-bloc'>
        {user && (
          <div className='d-flex wrap'>
            {inputs(user, customer).map((input, index) => {
              return <Input key={index} required={input.required} errors={errors} register={register} defaultValue={input.defaultValue} className='input' name={input.name} label={input.label} placeholder={input.placeholder} />;
            })}
          </div>
        )}
        <div className='birthday'>
          <label className='label'>Date de naissance*</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Date de naissance'
              value={value}
              minDate={dayjs('1900-01-01')}
              maxDate={dayjs(new Date())}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className='buttons'>
        <button onClick={onSubmit} type='submit' className={`button save ${isLoading ? 'disabled' : ''}`}>
          {isLoading && <CircularProgress />}
          <span>Envoyer</span>
        </button>
        <button className='button cancel'>Retour</button>
      </div>
      <span className='required'>* Champs requis</span>
    </form>
  );
};

export default EditProfile;
