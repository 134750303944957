import { createSlice } from '@reduxjs/toolkit';
import { getCustomer } from './customersAsyncThunk';

const initialState = {
  customer: null,
  isLoading: false,
  error: null,
};

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get one customer
      .addCase(getCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.customer = action.payload;
      })
      .addCase(getCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default customerSlice.reducer;
