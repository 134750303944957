import Input from 'components/Input/Input';
import Modal from 'components/Modal/Modal';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './AddFinanceRequest.scss';
import SelectInput from 'components/Select/Select';
import { useForm } from 'react-hook-form';
import { Breadcrumbs, CircularProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addingNewRequest,
  editingRequest,
  gettingCustomer,
  gettingOneRequest,
} from 'redux/slices/financial-request/financialRequestAsyncThunk';
import { emptyingOneRequest } from 'redux/slices/financial-request/financialRequestSlice';
import {
  getAdvanceAquiDropdown,
  getBuyModalityDropdown,
  getBuyModeDropdown,
  getDurationLoanDropdown,
  getGoodsTypeDropdown,
  getGoodsUseDropdown,
  getProSituationDropdown,
} from 'redux/slices/dropdown-finance/dropdownFinanceAsyncThunk';
import ErrorModal from 'components/Modal/ErrorModal';
import Status from 'components/Table/Status';

const AddFinanceRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { request, isLoading, error } = useSelector(
    (state) => state.requests.oneRequest
  );
  const oneRequest = useSelector((state) => state.requests.oneRequest);
  const user = useSelector((state) => state.auth.user);
  const goodsType = useSelector((state) => state.dropdowns);
  const { customer } = useSelector((state) => state.requests.customer);

  const path = useLocation();
  const { id } = useParams();

  const [disabled, setDisabled] = useState('');
  const [dropGoodsType, setDropGoodsType] = useState(null);
  const [advanceAqui, setAdvanceAqui] = useState(null);
  const [buyModality, setBuyModality] = useState(null);
  const [buyMode, setBuyMode] = useState(null);
  const [durationLoan, setDurationLoan] = useState(null);
  const [goodsUse, setGoodsUse] = useState(null);
  const [proSituation, setProSituation] = useState(null);

  const isEditPage = path.pathname.includes('edit-request');
  const isDetailPage = path.pathname.includes('/request/');

  const greenCityEmail = 'greencity-prescripteur@fibourse.com';

  const goToFinance = () => {
    navigate('/finance');
  };

  const onSubmit = (data) => {
    setDisabled('disabled');

    let dataToPatch = {
      email: request?.mail,
      firstName: request?.firstName,
      lastName: request?.lastName,
      phone: data.phoneNumber,
      s_situation_pro: data.situation,
      s_type_bien: data.goodsType,
      s_mode_achat: data.buyMode,
      s_modalite_achat: data.buyModality,
      s_usage_bien: data.usageType,
      s_etat_avancement: data.stateAdvAqu,
      s_montant_impot: data.annualCost,
      s_montant_global: data.sellPrice,
      s_montant_travaux: data.constructionCost,
      s_montant_apport: data.apport,
      s_montant_credit: data.monthlyCredit,
      s_acquisition: data.aquisitionCost,
      note: data.description,
      s_duree_credit_souhait: data.creditDuration,
      s_montant_mens_max: data.maxMonthly,
      s_email_agent: data.agentEmail ? data.agentEmail : request?.s_email_agent,
      customerUser: request?.customerUser,
      company: 'Inspire',
    };

    let dataToPost = {
      email: data.mail,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      s_situation_pro: data.situation,
      s_type_bien: data.goodsType,
      s_mode_achat: data.buyMode,
      s_modalite_achat: data.buyModality,
      s_usage_bien: data.usageType,
      s_etat_avancement: data.stateAdvAqu,
      s_montant_impot: data.annualCost,
      s_montant_global: data.sellPrice,
      s_montant_travaux: data.constructionCost,
      s_montant_apport: data.apport,
      s_montant_credit: data.monthlyCredit,
      s_acquisition: data.aquisitionCost,
      note: data.description,
      s_duree_credit_souhait: data.creditDuration,
      s_montant_mens_max: data.maxMonthly,
      customerUser: user?.id,
      s_email_agent: data.agentEmail ? data.agentEmail : greenCityEmail,
      company: 'Inspire',
      customer: 30,
      // la valeur du owner doit être changer sur la prod (à vérifier avec Afif consernant la valeur exacte)
      owner: 53,
    };

    if (path.pathname.includes('edit-request')) {
      dispatch(editingRequest({ id, data: dataToPatch }));
    } else if (path.pathname.includes('add-request')) {
      dispatch(addingNewRequest(dataToPost));
    }
  };
  <Input
    inputDisabled={true}
    defaultValue={request?.company ? request?.company : 'Inspire'}
    type='text'
    errors={errors}
    register={register}
    name='company'
    className='input'
    label='Agence'
    placeholder='Agence'
  />;

  useEffect(() => {
    if (
      path.pathname.includes('edit-request') ||
      path.pathname.includes('/request/')
    ) {
      dispatch(gettingOneRequest(id));
    }
  }, [path, id, dispatch]);

  useEffect(() => {
    if (request) {
      reset({
        defaultValues: {
          mail: request?.email,
          firstName: request?.firstName,
          lastName: request?.lastName,
          phoneNumber: request?.phone,
          situation: request?.s_situation_pro,
          goodsType: request?.s_type_bien,
          buyMode: request?.s_mode_achat,
          buyModality: request?.s_modalite_achat,
          usageType: request?.s_usage_bien,
          stateAdvAqu: request?.s_etat_avancement,
          annualCost: request?.s_montant_impot,
          sellPrice: request?.s_montant_global,
          constructionCost: request?.s_montant_travaux,
          apport: request?.s_montant_apport,
          monthlyCredit: request?.s_montant_credit,
          aquisitionCost: request?.s_acquisition,
          description: request?.note,
          creditDuration: request?.s_duree_credit_souhait,
          maxMonthly: request?.s_montant_mens_max,
          s_email_agent: request?.s_email_agent,
          customerUser: request?.customerUser,
          company: 'Inspire',
        },
      });
    }
  }, [request, reset]);

  useEffect(() => {
    dispatch(getGoodsTypeDropdown());
    dispatch(getBuyModeDropdown());
    dispatch(getBuyModalityDropdown());
    dispatch(getGoodsUseDropdown());
    dispatch(getAdvanceAquiDropdown());
    dispatch(getDurationLoanDropdown());
    dispatch(getProSituationDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (goodsType) {
      setDropGoodsType(goodsType.goodsType.data);
      setAdvanceAqui(goodsType.advanceAqui.data);
      setBuyModality(goodsType.buyModality.data);
      setBuyMode(goodsType.buyMode.data);
      setDurationLoan(goodsType.durationLoan.data);
      setGoodsUse(goodsType.goodsUse.data);
      setProSituation(goodsType.proSituation.data);
    }
  }, [goodsType]);

  useEffect(() => {
    if (oneRequest?.status === 'fulfilled') {
      navigate('/finance');
    }
  }, [navigate, oneRequest, dispatch]);

  useEffect(() => () => dispatch(emptyingOneRequest()), [dispatch]);

  useEffect(() => {
    if (oneRequest) {
      dispatch(gettingCustomer(oneRequest?.request?.customer));
    }
  }, [dispatch, oneRequest]);

  const editButton = () => {
    return (
      <div className='edit-button-container'>
        <Link to={`/finance/edit-request/${id}`}>
          <div className='edit-button'>
            <i className='icon-edit'></i>
            <span>Modifier</span>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='add-req'>
      <div className={`loader-${isLoading}`}>
        <CircularProgress />
      </div>
      <div className='title-container'>
        {isEditPage ? (
          <div>
            <h1>MODIFIER DEMANDE DE FINANCEMENT</h1>
            <Breadcrumbs
              className='bread'
              separator='>'
              aria-label='breadcrumb'
            >
              <Link className='bread-link' to='/'>
                Accueil
              </Link>
              <Link className='bread-link' to='/finance'>
                Demandes de financement
              </Link>
              <Typography color='text.primary'>
                Modifier la demande de financement
              </Typography>
            </Breadcrumbs>
          </div>
        ) : isDetailPage ? (
          <div className='d-flex full-width'>
            <Breadcrumbs
              className='bread'
              separator='>'
              aria-label='breadcrumb'
            >
              <Link className='bread-link' to='/finance'>
                Demandes de financement
              </Link>
              <Typography color='text.primary'>
                Modifier la demande de financement
              </Typography>
            </Breadcrumbs>
            <div className='buttons-container'>
              {editButton()}
              <Modal isDetailPage={isDetailPage} id={id} path={path} />
            </div>
          </div>
        ) : (
          <div>
            <h1>AJOUTER UNE DEMANDES DE FINANCEMENT</h1>
            <Breadcrumbs
              className='bread'
              separator='>'
              aria-label='breadcrumb'
            >
              <Link className='bread-link' to='/'>
                Accueil
              </Link>
              <Link className='bread-link' to='/finance'>
                Demandes de financement
              </Link>
              <Typography color='text.primary'>
                Ajouter une demande de financement
              </Typography>
            </Breadcrumbs>
          </div>
        )}
      </div>
      {isDetailPage && (
        <span className='blue-title state'>
          Etat du dossier : {request?.id && <Status id={request?.id} />}
        </span>
      )}
      <span className='blue-title'>Etat civil</span>
      <div className='user-info-bloc'>
        <Input
          inputDisabled={isEditPage || isDetailPage}
          defaultValue={request?.lastName}
          required={isEditPage ? false : true}
          errors={errors}
          register={register}
          type='text'
          name='lastName'
          className='input'
          label='Nom'
          placeholder='Doe'
        />
        <Input
          inputDisabled={isEditPage || isDetailPage}
          defaultValue={request?.firstName}
          required={isEditPage ? false : true}
          errors={errors}
          register={register}
          type='text'
          name='firstName'
          className='input'
          label='Prénom'
          placeholder='John'
        />
        <Input
          inputDisabled={isEditPage || isDetailPage}
          defaultValue={request?.email}
          required={isEditPage ? false : true}
          errors={errors}
          register={register}
          type='email'
          name='mail'
          className='input'
          label='Adresse email'
          placeholder='John@email.com'
        />
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.phone}
          required={true}
          errors={errors}
          register={register}
          type='tel'
          name='phone'
          className='input'
          label='Numéro de téléphone'
          placeholder='Numéro de téléphone'
        />
        {proSituation && (
          <SelectInput
            inputDisabled={isDetailPage}
            defaultValue={request?.s_situation_pro}
            required={true}
            errors={errors}
            register={register}
            name='situation'
            label='Situation Professionelle'
            page='add/edit'
            options={proSituation}
          />
        )}
      </div>
      <span className='blue-title'>Objet de ma demande de financement</span>
      <div className='subject-bloc'>
        {dropGoodsType && (
          <SelectInput
            inputDisabled={isDetailPage}
            page='add/edit'
            defaultValue={request?.s_type_bien}
            required={true}
            errors={errors}
            register={register}
            name='goodsType'
            label='Type de bien'
            options={dropGoodsType}
          />
        )}
        {goodsUse && (
          <SelectInput
            inputDisabled={isDetailPage}
            page='add/edit'
            defaultValue={request?.s_usage_bien}
            required={true}
            errors={errors}
            register={register}
            name='usageType'
            label='Usage du bien'
            options={goodsUse}
          />
        )}
        {buyModality && (
          <SelectInput
            inputDisabled={isDetailPage}
            page='add/edit'
            defaultValue={request?.s_modalite_achat}
            required={true}
            errors={errors}
            register={register}
            name='buyModality'
            label="Modalité d'achat"
            options={buyModality}
          />
        )}
        {buyMode && (
          <SelectInput
            inputDisabled={isDetailPage}
            page='add/edit'
            defaultValue={request?.s_mode_achat}
            required={true}
            errors={errors}
            register={register}
            name='buyMode'
            label="Mode d'achat"
            options={buyMode}
          />
        )}
        {advanceAqui && (
          <SelectInput
            inputDisabled={isDetailPage}
            page='add/edit'
            defaultValue={request?.s_etat_avancement}
            required={true}
            errors={errors}
            register={register}
            name='stateAdvAqu'
            label='Etat avancement acquisition'
            options={advanceAqui}
          />
        )}
      </div>
      <span className='blue-title'>Demande de financement</span>
      <div className='req-bloc'>
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_montant_impot}
          required={true}
          errors={errors}
          register={register}
          type='number'
          name='annualCost'
          className='input'
          label='Montant total revenus net imposable annuel (€)'
          placeholder='Montant total revenus net imposable annuel (€)'
        />
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_montant_credit}
          required={true}
          errors={errors}
          register={register}
          type='number'
          name='monthlyCredit'
          className='input'
          label='Montant total mensuel des crédits en cours (€)'
          placeholder='Montant total mensuel des crédits en cours (€)'
        />
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_montant_global}
          required={true}
          errors={errors}
          register={register}
          type='number'
          name='sellPrice'
          className='input'
          label='Prix de vente à financer (€)'
          placeholder='Prix de vente à financer (€)'
        />
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_acquisition}
          required={true}
          errors={errors}
          register={register}
          type='number'
          name='aquisitionCost'
          className='input'
          label='Dont prix acquisition terrain (€)'
          placeholder='Dont prix acquisition terrain (€)'
        />
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_montant_travaux}
          required={true}
          errors={errors}
          register={register}
          type='number'
          name='constructionCost'
          className='input'
          label='Dont prix travaux et/ou construction (€)'
          placeholder='Dont prix travaux et/ou construction (€)'
        />
      </div>
      <div className='separator' />
      <div className='solo-input'>
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_montant_apport}
          required={true}
          errors={errors}
          register={register}
          type='number'
          name='apport'
          className='input'
          label='Montant de l’apport possible (€)'
          placeholder='Montant de l’apport possible (€)'
        />
      </div>
      <span className='ten-percent'>
        Pour l'achat d'une résidence principale un apport d'au moins 10% est
        exigé
      </span>
      <div className='alone-input'>
        <div className='input comment-input'>
          <label className='required'>Description du projet</label>
          <textarea
            disabled={isDetailPage}
            defaultValue={request?.note}
            {...register('description', { required: true })}
            name='description'
            placeholder='Description du projet'
          />
        </div>
      </div>
      <div className='credit'>
        {durationLoan && (
          <SelectInput
            inputDisabled={isDetailPage}
            page='add/edit'
            defaultValue={request?.s_duree_credit_souhait}
            required={true}
            errors={errors}
            register={register}
            name='creditDuration'
            label='Durée du crédit souhaitée'
            options={durationLoan}
          />
        )}
        <Input
          inputDisabled={isDetailPage}
          defaultValue={request?.s_montant_mens_max}
          type='number'
          required={true}
          errors={errors}
          register={register}
          name='maxMonthly'
          className='input'
          label='Mensualité maximum souhaitée (€)'
          placeholder='Mensualité maximum souhaitée (€)'
        />
      </div>
      <span className='blue-title'>Zone réservée au conseiller</span>
      <div className='zone-bloc'>
        <Input
          inputDisabled={true}
          defaultValue={customer && customer?.name}
          type='text'
          errors={errors}
          register={register}
          name='customer'
          className='input'
          label='Socièté'
          placeholder='Socièté'
        />
        <Input
          defaultValue={
            request?.s_email_agent ? request?.s_email_agent : greenCityEmail
          }
          type='email'
          errors={errors}
          register={register}
          name='agentEmail'
          className='input'
          label='Agent (email)'
          placeholder='exemple@yahoo.fr'
        />
      </div>
      <div className='btns'>
        {!isDetailPage && (
          <button type='submit' className={`button send ${disabled}`}>
            {isLoading && <CircularProgress />}
            <span>Envoyer</span>
          </button>
        )}
        <button onClick={goToFinance} className='button cancel'>
          Retour
        </button>
      </div>
      <ErrorModal error={error} />
    </form>
  );
};

export default AddFinanceRequest;
