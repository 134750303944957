import { createSlice } from '@reduxjs/toolkit';
import { getGoodsTypeDropdown, getBuyModeDropdown, getBuyModalityDropdown, getGoodsUseDropdown, getAdvanceAquiDropdown, getDurationLoanDropdown, getProSituationDropdown } from './dropdownFinanceAsyncThunk';

const initialState = {
    goodsType: {
        data: null,
        isLoading: false,
        error: null,
    },
    buyMode: {
        data: null,
        isLoading: false,
        error: null,
    },
    buyModality: {
        data: null,
        isLoading: false,
        error: null,
    },
    goodsUse: {
        data: null,
        isLoading: false,
        error: null,
    },
    advanceAqui: {
        data: null,
        isLoading: false,
        error: null,
    },
    durationLoan: {
        data: null,
        isLoading: false,
        error: null,
    },
    proSituation: {
        data: null,
        isLoading: false,
        error: null,
    }
};

export const dropdownFinanceSlice = createSlice({
    name: 'dropdownFinance',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // get goods type dropdown
            .addCase(getGoodsTypeDropdown.pending, (state) => {
                state.goodsType.isLoading = true;
            })
            .addCase(getGoodsTypeDropdown.fulfilled, (state, action) => {
                state.goodsType.isLoading = false;
                state.goodsType.error = null;
                state.goodsType.data = action.payload;
            })
            .addCase(getGoodsTypeDropdown.rejected, (state, action) => {
                state.goodsType.isLoading = false;
                state.goodsType.error = action.payload;
            })

            // get buy mode dropdown
            .addCase(getBuyModeDropdown.pending, (state) => {
                state.buyMode.isLoading = true;
            })
            .addCase(getBuyModeDropdown.fulfilled, (state, action) => {
                state.buyMode.isLoading = false;
                state.buyMode.error = null;
                state.buyMode.data = action.payload;
            })
            .addCase(getBuyModeDropdown.rejected, (state, action) => {
                state.buyMode.isLoading = false;
                state.buyMode.error = action.payload;
            })

            // get buy modality dropdown
            .addCase(getBuyModalityDropdown.pending, (state) => {
                state.buyModality.isLoading = true;
            })
            .addCase(getBuyModalityDropdown.fulfilled, (state, action) => {
                state.buyModality.isLoading = false;
                state.buyModality.error = null;
                state.buyModality.data = action.payload;
            })
            .addCase(getBuyModalityDropdown.rejected, (state, action) => {
                state.buyModality.isLoading = false;
                state.buyModality.error = action.payload;
            })

            // get goods use dropdown
            .addCase(getGoodsUseDropdown.pending, (state) => {
                state.goodsUse.isLoading = true;
            })
            .addCase(getGoodsUseDropdown.fulfilled, (state, action) => {
                state.goodsUse.isLoading = false;
                state.goodsUse.error = null;
                state.goodsUse.data = action.payload;
            })
            .addCase(getGoodsUseDropdown.rejected, (state, action) => {
                state.goodsUse.isLoading = false;
                state.goodsUse.error = action.payload;
            })

            // get advance aquisition dropdown
            .addCase(getAdvanceAquiDropdown.pending, (state) => {
                state.advanceAqui.isLoading = true;
            })
            .addCase(getAdvanceAquiDropdown.fulfilled, (state, action) => {
                state.advanceAqui.isLoading = false;
                state.advanceAqui.error = null;
                state.advanceAqui.data = action.payload;
            })
            .addCase(getAdvanceAquiDropdown.rejected, (state, action) => {
                state.advanceAqui.isLoading = false;
                state.advanceAqui.error = action.payload;
            })

            // get loan duration dropdown
            .addCase(getDurationLoanDropdown.pending, (state) => {
                state.durationLoan.isLoading = true;
            })
            .addCase(getDurationLoanDropdown.fulfilled, (state, action) => {
                state.durationLoan.isLoading = false;
                state.durationLoan.error = null;
                state.durationLoan.data = action.payload;
            })
            .addCase(getDurationLoanDropdown.rejected, (state, action) => {
                state.durationLoan.isLoading = false;
                state.durationLoan.error = action.payload;
            })

            // get professional situation dropdown
            .addCase(getProSituationDropdown.pending, (state) => {
                state.proSituation.isLoading = true;
            })
            .addCase(getProSituationDropdown.fulfilled, (state, action) => {
                state.proSituation.isLoading = false;
                state.proSituation.error = null;
                state.proSituation.data = action.payload;
            })
            .addCase(getProSituationDropdown.rejected, (state, action) => {
                state.proSituation.isLoading = false;
                state.proSituation.error = action.payload;
            })
    },
});

export default dropdownFinanceSlice.reducer;
