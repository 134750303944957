import { API, API_HOST_FICODEV } from 'configs/api';
import { useEffect, useState } from 'react';
import axiosService from 'services/axiosServices';

const Status = ({ id }) => {
  const [status, setStatus] = useState('');

  const getStatus = async () => {
    let data = await axiosService.get(API_HOST_FICODEV + API.request.requests + '/' + id + '/s_etat_avancement').then((res) => {
      setStatus(res.data);
    });
    return data;
  };

  /* eslint-disable */
  useEffect(() => {
    if (id) {
      getStatus();
    }
  }, [id]);
  /* eslint-enable */

  return <div className='status'> {status && status.name}</div>;
};

export default Status;
