import React, { useEffect, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SelectInput = ({ label, options, name, placeholder, register, errors, defaultValue, page, inputDisabled }) => {
  const [selectClass, setSelectClass] = useState('');
  const [val, setVal] = useState('');

  const handleChange = (e) => {
    setSelectClass('-selected');
    setVal(e.target.value);
  };

  useEffect(() => {
    setVal(defaultValue === undefined ? '' : defaultValue);
    if (val !== '') {
      setSelectClass('-selected');
    }
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (name === 'contactBy') {
      setSelectClass('-selected');
    }
  }, [name]);

  return (
    <div className='input'>
      <label className='required'>{label}</label>
      <Select disabled={inputDisabled} labelId='demo-simple-select-label' className={`select-placeholder${selectClass} error-${val === '' && errors[name] && errors[name].type === 'required'}`} {...register(`${name}`, { required: true })} onChange={handleChange} displayEmpty={true} inputProps={{ 'aria-label': 'Without label' }} name={name} value={val}>
        <MenuItem disabled value='none'>
          {label}
        </MenuItem>
        {options.map((option) => {
          return (
            <MenuItem key={page === 'add/edit' ? option.name : option} value={page === 'add/edit' ? option.id : option}>
              {page === 'add/edit' ? option.name : option}
            </MenuItem>
          );
        })}
      </Select>
      {val === '' && errors[name] && errors[name].type === 'required' && <span className='error-message'>{`Le champ ${label.toLowerCase()} est obligatoire`}</span>}
    </div>
  );
};

export default SelectInput;
