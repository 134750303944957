import React, { useState } from 'react';

import AnexeTable from './components/AnexeTable';
import PropositionsFilters from './components/PropositionsFilters';
import PropositionsTable from './components/PropositionsTable';

import './Proposition.scss';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Proposition = () => {
  const { data, isLoading } = useSelector((state) => state.propositions);

  const [currentPage, setCurrentPage] = useState(1);
  const loggedUserId = useSelector((state) => state.auth?.user?.id);

  return (
    <div className='proposition-container'>
      <div className='filter-new-prop-bloc'>
        <h1>PROPOSITIONS</h1>
        <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
          <Link className='bread-link' to='/'>
            Accueil
          </Link>
          <Typography color='text.primary'>Propositions</Typography>
        </Breadcrumbs>
      </div>

      <PropositionsFilters id={loggedUserId} currentPage={currentPage} />
      <PropositionsTable loggedUserId={loggedUserId} data={data} isLoading={isLoading} setCurrentPage={setCurrentPage} currentPage={currentPage} />

      <AnexeTable />
    </div>
  );
};

export default Proposition;
