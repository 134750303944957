import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, API_HOST_FICODEV } from 'configs/api';
import axiosService from 'services/axiosServices';

export const getPDVF = createAsyncThunk(
  'propositions/getPDFV',
  async (id, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/request`;

    try {
      const response = await axiosService.get(URL);
      const { headers, data } = response;

      // Ignore non-serializable properties of response object
      const serializableHeaders = {
        'content-type': headers['content-type'],
        'content-length': headers['content-length'],
      };

      return fulfillWithValue({ headers: serializableHeaders, data });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    // Ignore non-serializable properties of the action
    serializableCheck: {
      ignoredActions: ['propositions/getPDFV/fulfilled'],
    },
  }
);

export const getPropositions = createAsyncThunk('propositions/getPropositions', async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const baseUrl = API_HOST_FICODEV + API.propositions.list

  const user = `?customerUser=${data.id}`
  const page = '&page=' + data.page
  const filterId = data.filterId ? '&id=' + data.filterId : ''
  const customer_status = data.customer_status ? '&internal_status=' + data.customer_status : ''
  const customer = data.customer ? '&customer=' + data.customer : ''

  const URL = baseUrl + user + page + filterId + customer_status + customer + '&created_at=2023-12-25'

  return axiosService
    .get(URL)
    .then(async (res) => {
      const propositions = res.data;
      const propositionsWithPDVF = [];

      for (const proposition of propositions) {
        const request = await dispatch(getPDVF(proposition.id));
        const pdvf = request.payload.data.s_montant_global;
        const propositionWithPDVF = { ...proposition, pdvf };

        propositionsWithPDVF.push(propositionWithPDVF);
      }

      return fulfillWithValue(propositionsWithPDVF);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOneProposition = createAsyncThunk('propositions/getOneProposition', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOnePropositionCustomer = createAsyncThunk('propositions/getOnePropositionCustomer', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/customerUser`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOnePropositionAssignee = createAsyncThunk('propositions/getOnePropositionAssignee', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/assignedUsers`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data[0]);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOnePropositionCompany = createAsyncThunk('propositions/getOnePropositionCompany', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/customer`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOnePropositionFileOne = createAsyncThunk('propositions/getOnePropositionFileOne', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/s_quote_att_1`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOnePropositionFileTwo = createAsyncThunk('propositions/getOnePropositionFileTwo', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/s_quote_att_2`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => rejectWithValue(err));
});

export const getOnePropositionFileThree = createAsyncThunk('propositions/getOnePropositionFileThree', async (id, { fulfillWithValue, rejectWithValue, dispatch }) => {
  const URL = `${API_HOST_FICODEV}${API.propositions.list}/${id}/s_quote_att_3`;

  return axiosService
    .get(URL)
    .then(async (res) => {
      return fulfillWithValue(res.data);
    })
    .catch((err) => rejectWithValue(err));
});