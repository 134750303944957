import { createSlice } from '@reduxjs/toolkit';
import { gettingAllRequests, gettingOneRequest, editingRequest, addingNewRequest, deletingRequest, gettingCustomer } from './financialRequestAsyncThunk';

const initialState = {
  requests: {
    requests: null,
    isLoading: false,
    error: null,
  },
  oneRequest: {
    request: null,
    isLoading: false,
    error: null,
    status: 'idle'
  },
  customer: {
    customer: null,
    isLoading: false,
    error: null
  }
};

export const financialRequestSlice = createSlice({
  name: 'financialRequest',
  initialState,
  reducers: {
    emptyingOneRequest: (state) => {
      state.oneRequest.isLoading = false;
      state.oneRequest.error = null;
      state.oneRequest.request = null;
      state.oneRequest.status = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      // get all requests
      .addCase(gettingAllRequests.pending, (state) => {
        state.requests.isLoading = true;
      })
      .addCase(gettingAllRequests.fulfilled, (state, action) => {
        state.requests.isLoading = false;
        state.requests.error = null;
        state.requests.requests = action.payload;
      })
      .addCase(gettingAllRequests.rejected, (state, action) => {
        state.requests.isLoading = false;
        state.requests.error = action.payload.message;
      })

      // get one request
      .addCase(gettingOneRequest.pending, (state) => {
        state.oneRequest.isLoading = true;
      })
      .addCase(gettingOneRequest.fulfilled, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = null;
        state.oneRequest.request = action.payload;
      })
      .addCase(gettingOneRequest.rejected, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = action.payload.message;
      })

      // edit one request
      .addCase(editingRequest.pending, (state) => {
        state.oneRequest.isLoading = true;
      })
      .addCase(editingRequest.fulfilled, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = null;
        state.oneRequest.status = 'fulfilled';
        state.oneRequest.request = action.payload;
      })
      .addCase(editingRequest.rejected, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = action.payload;
      })

      // add new request
      .addCase(addingNewRequest.pending, (state) => {
        state.oneRequest.isLoading = true;
      })
      .addCase(addingNewRequest.fulfilled, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = null;
        state.oneRequest.status = 'fulfilled';
        state.oneRequest.request = action.payload;
      })
      .addCase(addingNewRequest.rejected, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = action.payload;
      })

      // delete request
      .addCase(deletingRequest.pending, (state) => {
        state.oneRequest.isLoading = true;
      })
      .addCase(deletingRequest.fulfilled, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = null;
        state.oneRequest.request = action.payload;
      })
      .addCase(deletingRequest.rejected, (state, action) => {
        state.oneRequest.isLoading = false;
        state.oneRequest.error = action.payload;
      })

      // get customer
      .addCase(gettingCustomer.pending, (state) => {
        state.customer.isLoading = true;
      })
      .addCase(gettingCustomer.fulfilled, (state, action) => {
        state.customer.isLoading = false;
        state.customer.error = null;
        state.customer.customer = action.payload;
      })
      .addCase(gettingCustomer.rejected, (state, action) => {
        state.customer.isLoading = false;
        state.customer.error = action.payload;
      })
  },
});

export const { emptyingOneRequest } = financialRequestSlice.actions

export default financialRequestSlice.reducer;
