import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import CircularProgress from '@mui/material/CircularProgress';

import { login } from 'redux/slices/authentification/authAsyncThunk';

import './Login.scss';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordType, setPasswordType] = useState('password');
  const { register, handleSubmit } = useForm();
  const auth = useSelector((state) => state.auth);

  const togglePassword = () =>
    passwordType === 'password'
      ? setPasswordType('text')
      : setPasswordType('password');
  const loginUser = (data) => dispatch(login(data));

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const onSubmit = () => {
    const data = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };
    loginUser(data);
  };

  useEffect(() => {
    if (!auth.isLoading && auth.login) navigate('/finance');
  }, [auth.isLoading, auth.login, navigate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='login'>
      <div>
        <h1>connexion</h1>
        <div className='input'>
          <label>Adresse Email *</label>
          <input
            autoComplete='username'
            {...register('email')}
            name='email'
            required={true}
            type='email'
            ref={emailRef}
            placeholder='nom@exemple.com'
          />
        </div>
        <div className='pass-input-container'>
          <div className='input'>
            <label>Mot de passe *</label>
            <input
              autoComplete='current-password'
              {...register('password')}
              name='password'
              required={true}
              type={passwordType}
              ref={passwordRef}
              placeholder='Mot de passe'
            />
          </div>
          {passwordType === 'password' ? (
            <i className='eye gray icon-eye-off' onClick={togglePassword}></i>
          ) : (
            <i className='eye gray icon-eye-open' onClick={togglePassword}></i>
          )}
        </div>
        {auth.error && (
          <span className='error-message'>Email ou mot de passe invalide.</span>
        )}

        <div className='stay-connected-container'>
          <div className='stay-connected'>
            <input className='check' type='checkbox' />
            <span className='light-gray'>Rester Connecter</span>
          </div>
          <Link to='/forgot'>
            <span className='dark-blue forgot'>Mot de passe oublié</span>
          </Link>
        </div>

        <button
          type='submit'
          className={`beige-button button ${auth.isLoading && 'disabled'}`}
        >
          {auth.isLoading && <CircularProgress />}
          <span>connexion</span>
        </button>
      </div>
      <span className='got-account'>
        Vous n'avez pas de compte?
        <Link to={{ pathname: '/register' }}>
          <span className='dark-blue forgot'> Inscrivez-vous</span>
        </Link>
      </span>
    </form>
  );
};

export default Login;
