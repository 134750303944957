import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_HOST, API } from 'configs/api';

export const gettingAboutUsPage = createAsyncThunk('staticPages/aboutUs', async (_, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST + API.static.about;

  return axios
    .get(URL)
    .then((res) => fulfillWithValue(res.data.data.content))
    .catch((err) => rejectWithValue(err));
});

export const gettingLegalNoticePage = createAsyncThunk('staticPages/legalNotice', async (_, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST + API.static.legal;

  return axios
    .get(URL)
    .then((res) => fulfillWithValue(res.data.data.content))
    .catch((err) => rejectWithValue(err));
});

export const gettingCookiesPage = createAsyncThunk('staticPages/cookies', async (_, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST + API.static.cookies;

  return axios
    .get(URL)
    .then((res) => fulfillWithValue(res.data.data.content))
    .catch((err) => rejectWithValue(err));
});

export const contactingGreenCity = createAsyncThunk('staticPages/contact', async (data, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST + API.static.contact;

  return axios
    .post(URL, data)
    .then((res) => fulfillWithValue(res))
    .catch((err) => rejectWithValue(err));
});
