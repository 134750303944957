import React, { useState } from 'react';
import './Input.scss';

const Input = ({ label, type, placeholder, errors, register, name, required, defaultValue, inputDisabled, pattern }) => {
  const [val, setVal] = useState('')

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  const errorCondition = () => {
    if (name === 'email' || name === 'phoneNumber') {
      if (required && val === '') {
        return true;
      } else {
        return false;
      }
    } else {
      return val === '' && errors[name] && errors[name].type === 'required';
    }
  };

  return (
    <div className='input '>
      <label className={required ? 'required' : undefined}>{label}</label>

      <input disabled={inputDisabled ? inputDisabled : false} className={`error-${errorCondition()}`} placeholder={placeholder} {...register(`${name}`, { required: name !== 'email' || name !== 'phoneNumber' ? required : false })} defaultValue={defaultValue} name={name} onChange={handleChange} type={type} />
      {errorCondition() && <span className='error-message'>{`Le champ '${label.toLowerCase()}' est obligatoire`}</span>}
      {errors[name] && errors[name].type === 'pattern' && <span className='error-message'>{`Le champ '${label.toLowerCase()}' est invalide`}</span>}
    </div>
  );
};

export default Input;
