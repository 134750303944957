import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_HOST_FICODEV, API } from 'configs/api';
import axiosService from 'services/axiosServices';

export const gettingAllRequests = createAsyncThunk('financialRequest/getFinancialRequests', async (data, { fulfillWithValue, rejectWithValue }) => {
  const baseUrl = API_HOST_FICODEV + API.request.requests
  const user = '?customerUser=' + data.id
  const email = data.s_email_agent ? '&s_email_agent=' + data.s_email_agent : ''
  const internalStat = data.internalStatus ? '&internal_status=' + data.internalStatus : ''
  const page = '&page=' + data.page
  const financeId = data.financeId ? '&id=' + data.financeId : ''

  const URL = baseUrl + user + internalStat + email + page + financeId;

  return axiosService
    .get(URL)
    .then((res) => fulfillWithValue(res.data))
    .catch((err) => rejectWithValue(err));
});

export const addingNewRequest = createAsyncThunk('financialRequest/addFinancialRequests', async (data, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST_FICODEV + API.request.requests;

  return axiosService
    .post(URL, data)
    .then((res) => fulfillWithValue(res.data))
    .catch((err) => rejectWithValue(err));
});

export const gettingOneRequest = createAsyncThunk('financialRequest/getOneFinancialRequest', async (id, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST_FICODEV + API.request.requests + '/' + id;

  return axiosService
    .get(URL)
    .then((res) => fulfillWithValue(res.data))
    .catch((err) => rejectWithValue(err));
});

export const editingRequest = createAsyncThunk('financialRequest/editFinancialRequest', async (data, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST_FICODEV + API.request.requests + '/' + data.id;

  return axiosService
    .patch(URL, data.data)
    .then((res) => fulfillWithValue(res.data))
    .catch((err) => rejectWithValue(err));
});

export const deletingRequest = createAsyncThunk('financialRequest/deleteFinancialRequest', async (id, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST_FICODEV + API.request.requests + '/' + id;

  return axiosService
    .delete(URL)
    .then((res) => fulfillWithValue(res.data))
    .catch((err) => rejectWithValue(err));
});

export const gettingCustomer = createAsyncThunk('financialRequest/getOneCustomer', async (id, { fulfillWithValue, rejectWithValue }) => {
  const URL = API_HOST_FICODEV + API.request.customers + '/' + id;

  return axiosService
    .get(URL)
    .then((res) => fulfillWithValue(res.data))
    .catch((err) => rejectWithValue(err));
});