import { Breadcrumbs, CircularProgress, Typography } from '@mui/material';
import Input from 'components/Input/Input';
import ErrorModal from 'components/Modal/ErrorModal';
import SelectInput from 'components/Select/Select';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { contactingGreenCity } from 'redux/slices/static-pages/staticPagesAsyncThunk';
import './ContactUs.scss';
import { Link } from 'react-router-dom';

const ContactUs = () => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(
    (state) => state.staticPages.contact
  );
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const contactBy = watch('contactBy');

  const isContactByBothValid = contactBy === 'Téléphone/Email';
  const isContactByEmailValid = contactBy === 'Email' || isContactByBothValid;
  const isContactByPhoneValid =
    contactBy === 'Téléphone' || isContactByBothValid;

  const onSubmit = (data) => {
    dispatch(contactingGreenCity(data));
  };

  useEffect(() => {
    reset({
      defaultValues: {
        contactBy: 'Email',
        reason: 'Professionelle',
      },
    });
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='contact'>
      <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
        <Link className='bread-link' to='/'>
          Accueil
        </Link>
        <Typography color='text.primary'>Contactez-nous</Typography>
      </Breadcrumbs>
      <h1>CONTACTEZ-NOUS</h1>
      <div className='name'>
        <Input
          required={true}
          errors={errors}
          register={register}
          name='firstName'
          label='Prénom'
          type='text'
          placeholder='John'
          pattern={/^([a-zA-Z0-9_-]){2,9}$/}
        />
        <Input
          required={true}
          errors={errors}
          register={register}
          name='lastName'
          label='Nom'
          type='text'
          placeholder='Doe'
          pattern={/^([a-zA-Z0-9_-]){2,9}$/}
        />
      </div>
      <div className='name alone-input'>
        <Input
          required={true}
          errors={errors}
          register={register}
          name='organization'
          label={"Nom de l'Organization si vous êtes professionnel"}
          type='text'
          placeholder={"Nom de l'Organization si vous êtes professionnel"}
          pattern={/^([a-zA-Z0-9_-]){2,9}$/}
        />
      </div>
      <div className='input'>
        <SelectInput
          errors={errors}
          register={register}
          defaultValue='Email'
          name='contactBy'
          placeholder='Email'
          label='Méthode de contact préférée'
          options={['Email', 'Téléphone', 'Téléphone/Email']}
        />
      </div>
      <div className='name'>
        <Input
          required={isContactByPhoneValid}
          errors={errors}
          register={register}
          name='phoneNumber'
          label='Téléphone'
          type='number'
          placeholder='Téléphone'
          pattern={/^([a-zA-Z0-9_-]){6,9}$/}
        />
        <Input
          required={isContactByEmailValid}
          errors={errors}
          register={register}
          name='email'
          label='Email'
          type='email'
          placeholder='Email'
          pattern={
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          }
        />
      </div>
      <div className='input'>
        <SelectInput
          errors={errors}
          register={register}
          name='reason'
          defaultValue='Professionelle'
          placeholder='Professionelle'
          label='Raison de contact'
          options={['Professionelle', 'Personelle']}
        />
      </div>
      <div className='input comment-input'>
        <label className='required'>Commentaire</label>
        <textarea
          className={`error-${
            errors.comment && errors.comment.type === 'required'
          }`}
          placeholder='Commentaire...'
          {...register('comment', { required: true })}
          name='comment'
        />
        {errors.comment && errors.comment.type === 'required' && (
          <span className='error-message'>
            Le champ Commentaires est obligatoire
          </span>
        )}
      </div>
      <button
        type='submit'
        onClick={handleSubmit(onSubmit)}
        className={`beige-button button ${isLoading && 'disabled'}`}
      >
        {isLoading && <CircularProgress />}
        <span>Envoyer</span>
      </button>
      <ErrorModal error={error} />
    </form>
  );
};

export default ContactUs;
