import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const FilterList = ({ data, title, setFilter }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => setAnchorEl(null);

    const handleChoose = (e) => {
        setFilter(e.target.id)
        handleClose()
    }

    return (
        <div>
            <div
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className='one-filter'>
                <span>{title}</span>
                <i className='icon-arrowdown' ></i>
            </div>
            <Menu
                className='menu'
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        marginTop: '8px',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        padding: 0
                    }
                }}
            >
                {
                    title === 'Status interne' &&
                    <MenuItem className='filter-element' id='open' onClick={handleChoose}>
                        <span id='open'>Nouveau</span>
                    </MenuItem>
                }
                {
                    data?.map((el, index) => {
                        return (
                            <MenuItem className='filter-element' id={el.id} key={index} onClick={handleChoose}>
                                {el.name}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    );
}

export default FilterList
