import * as React from 'react';
import './Modal.scss';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { deletingRequest } from 'redux/slices/financial-request/financialRequestAsyncThunk';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Modal({ path, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const { isLoading, request } = useSelector((state) => state.requests.oneRequest);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRequest = () => {
    dispatch(deletingRequest(id));
  };

  React.useEffect(() => {
    if (request === '' && !isLoading) {
      navigate('/finance');
    }
  }, [isLoading, request, navigate]);

  return (
    <div>
      {(path.pathname.includes('/finance/edit-request') || path.pathname.includes('/request/')) && (
        <div className='modal-button' onClick={handleClickOpen}>
          <i className='icon-trash'></i>
          <span>Supprimer</span>
        </div>
      )}
      <Dialog className='delete-modal' open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <span>Voulez-vous vraiment supprimer cette demande?</span>
          <i className='icon-plus' onClick={handleClose}></i>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla blandit dui, vel feugiat magna euismod ac. Nunc ultricies leo quis risus congue porttitor.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className='delete-button button' onClick={handleDeleteRequest}>
            {isLoading ? <CircularProgress /> : <i className='icon-trash'></i>}
            <span>Supprimer</span>
          </button>
          <button className='gray-button button' onClick={handleClose}>
            <span>Annuler</span>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
