import React from 'react';

import { CircularProgress } from '@mui/material';
import AddressesTable from './AddressesTable';

const AddressesTableContainer = ({ isLoading, addresses }) => {
  return (
    <>
      <div className='address'>
        <i className='icon-map'></i>
        <span className='sub-title'>ADRESSES PAR DÉFAUT</span>
      </div>

      <div className='separator' />
      <div className='table-container'>
        {isLoading ? (
          <CircularProgress />
        ) : addresses?.length !== 0 ? (
          <AddressesTable addresses={addresses} />
        ) : (
          <div className='no-address'>
            <span>Le carnet d'adresses est vide</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddressesTableContainer;
