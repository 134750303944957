import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Input from 'components/Input/Input';
import { Breadcrumbs, CircularProgress, Typography } from '@mui/material';
import { getOneProposition, getOnePropositionAssignee, getOnePropositionCompany, getOnePropositionCustomer, getOnePropositionFileOne, getOnePropositionFileThree, getOnePropositionFileTwo } from 'redux/slices/propositions/propositionsAsyncThunk';
import { emptyingOneProp } from 'redux/slices/propositions/propositionsSlice';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

const PropositionDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
  } = useForm();
  const { oneProp, isLoading, customer, assignee, company, fileOne, fileTwo, fileThree } = useSelector((state) => state.propositions);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getOneProposition(id))
    dispatch(getOnePropositionCustomer(id))
    dispatch(getOnePropositionAssignee(id))
    dispatch(getOnePropositionCompany(id))
    dispatch(getOnePropositionFileOne(id))
    dispatch(getOnePropositionFileTwo(id))
    dispatch(getOnePropositionFileThree(id))
  }, [dispatch, id]);

  const goToPropositions = () => {
    navigate('/proposition');
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format("DD/MM/YYYY à hh:mm")
  };

  const handleDownload = (data) => {
    try {
      if (!data.content || typeof data.content !== 'string') {
        console.error('File content is empty or not valid.');
        return;
      }
      const bytes = Uint8Array.from(atob(data.content), (c) => c.charCodeAt(0));

      const blob = new Blob([bytes], { type: data.mimeType });

      saveAs(blob, data.originalFilename);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(() => () => dispatch(emptyingOneProp()), [dispatch]);

  return (
    <form onSubmit={() => { }} className='add-req'>
      <div className={`loader-${isLoading}`}>
        <CircularProgress />
      </div>
      <h1>DETAIL PROPOSITION</h1>
      <Breadcrumbs className='bread' separator='>' aria-label='breadcrumb'>
        <Link className='bread-link' to='/'>
          Accueil
        </Link>
        <Link className='bread-link' to='/proposition'>
          Propositions
        </Link>
        <Typography color='text.primary'>Détail proposition</Typography>
      </Breadcrumbs>
      <span className='blue-title'>Informations générales</span>
      <div className='user-info-bloc'>
        {company && <Input inputDisabled={true} errors={errors} register={register} className='input' label='Société' defaultValue={company?.name} />}
      </div>
      <span className='blue-title'>Information proposition</span>
      <span className='poNumber'>{oneProp?.poNumber ? oneProp?.poNumber : 'indisponible'}</span>
      {
        <div className='user-info-bloc'>
          {
            customer && (
              <div className='user-info-bloc'>
                <Input inputDisabled={true} errors={errors} register={register} className='input' label="Prénom" defaultValue={customer.firstName || 'indisponible'} />
                <Input inputDisabled={true} errors={errors} register={register} className='input' label="Nom de famille" defaultValue={customer.lastName || 'indisponible'} />
                {oneProp && (
                  <>
                    <Input inputDisabled={true} errors={errors} register={register} className='input' label="Valide jusqu’à" defaultValue={oneProp.validUntil ? formatDate(oneProp.validUntil) : 'indisponible'} />
                    <Input inputDisabled={true} errors={errors} register={register} className='input' label="Shipping Estimate" defaultValue={oneProp.overriddenShippingCostAmount || 'indisponible'} />
                    <Input inputDisabled={true} errors={errors} register={register} className='input' label="Fichier" defaultValue={oneProp.overriddenShippingCostAmount || 'indisponible'} />
                  </>
                )}
                {assignee && (
                  <Input inputDisabled={true} errors={errors} register={register} className='input' label="Assigné à" defaultValue={`${assignee.firstName} ${assignee.lastName}` || 'indisponible'} />
                )}
              </div>
            )
          }
        </div>
      }
      <span className='blue-title'>Fichiers</span>
      {
        fileOne?.length !== 0 || fileTwo?.length !== 0 || fileThree?.length !== 0 ?
          <div className='poNumber'>
            {fileOne?.length !== 0 &&
              <div onClick={() => handleDownload(fileOne)}>
                <span className='one-file'>{fileOne?.originalFilename}</span>
              </div>
            }
            {fileTwo?.length !== 0 &&
              <div onClick={() => handleDownload(fileTwo)}>
                <span className='one-file'>{fileTwo?.originalFilename}</span>
              </div>
            }
            {fileThree?.length !== 0 &&
              <div onClick={() => handleDownload(fileThree)}>
                <span className='one-file'>{fileThree?.originalFilename}</span>
              </div>
            }
          </div> :
          <div className='poNumber'>
            Aucun fichier pour le moment
          </div>
      }
      <div className='btns'>
        <button onClick={goToPropositions} className='button cancel'>
          Retour
        </button>
      </div>
    </form>
  );
};

export default PropositionDetail;
