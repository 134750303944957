import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_HOST_FICODEV, API } from 'configs/api';
import axiosService from 'services/axiosServices';

export const getGoodsTypeDropdown = createAsyncThunk(
    'dropdownFinance/getGoodsTypeDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.goodsType;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const getBuyModeDropdown = createAsyncThunk(
    'dropdownFinance/getBuyModeDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.buyMode;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const getBuyModalityDropdown = createAsyncThunk(
    'dropdownFinance/getBuyModalityDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.buyModality;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const getGoodsUseDropdown = createAsyncThunk(
    'dropdownFinance/getGoodsUseDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.goodsUse;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const getAdvanceAquiDropdown = createAsyncThunk(
    'dropdownFinance/getAdvanceAquiDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.advanceAqui;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const getDurationLoanDropdown = createAsyncThunk(
    'dropdownFinance/getDurationLoanDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.durationLoan;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);

export const getProSituationDropdown = createAsyncThunk(
    'dropdownFinance/getProSituationDropdown',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const URL = API_HOST_FICODEV + API.dropdown.proSituation;

        return axiosService
            .get(URL)
            .then((res) => fulfillWithValue(res.data))
            .catch((err) => rejectWithValue(err));
    }
);