import React from 'react'

const AnexeTable = () => {
    return (
        <div className='legend'>
            <span className='title'>Légende</span>

            <ul className='legend-list'>
                <li> PDVF : Prix de vente à financer, montant de votre demande de financement</li>
            </ul>
        </div>
    )
}

export default AnexeTable