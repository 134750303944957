import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AddressesTable = ({ addresses }) => {
  const tableCells = ['Name', 'Organization', 'City', 'Code Postal', 'Street'];

  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            {tableCells.map((cell, key) => (
              <TableCell key={key}>{cell}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {addresses?.map((row, key) => (
            <TableRow key={key}>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.organization}</TableCell>
              <TableCell>{`${row.city}, ${row.country}`} </TableCell>
              <TableCell>{row.postalCode}</TableCell>
              <TableCell>{row.street}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddressesTable;
