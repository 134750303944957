import { Link } from "react-router-dom";

import InternalStatus from "./InternalStatus";
import Customer from "./Customer";
import Status from "./Status";

import "./Table.scss";
import dayjs from "dayjs";

const Table = ({ requests, type }) => {
  let euroFrench = Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  const makeDate = (date) => {
    if (date) {
      return dayjs(date).format("DD/MM/YYYY à hh:mm");
    }
  };

  return (
    <div className="table-container">
      <table cellSpacing="0" cellPadding="0">
        <thead>
          {type === "proposition" ? (
            <tr>
              <th>Numéro</th>
              <th>Créé le</th>
              <th>Valide jusqu'au</th>
              <th>PDVF</th>
              <th>Agence</th>
              <th>Statut interne</th>
              <th></th>
            </tr>
          ) : (
            <tr>
              <th>Numéro</th>
              <th>Statut</th>
              <th>Créé le</th>
              <th>Statut interne</th>
              <th>PDVF</th>
              <th>NTDFC</th>
              <th>DEHDDC</th>
              <th>Société</th>
              <th>Agent</th>
              <th></th>
            </tr>
          )}
        </thead>
        <tbody>
          {requests?.map((el, index) => {
            return type === "proposition" ? (
              <tr key={index} className="proposition-cols">
                <td>{el.id}</td>
                <td>{makeDate(el.createdAt)}</td>
                <td>{makeDate(el.validUntil)}</td>
                <td>{el?.pdvf && `${el?.pdvf}€`}</td>
                <Customer type='proposition' id={el.customer} />
                <td>{el.internal_status}</td>
                <td className="actions">
                  <Link
                    className="gray"
                    to={{ pathname: "/proposition/" + el.id }}
                  >
                    <i className="icon-eye"></i>
                  </Link>
                </td>
              </tr>
            ) : (
              <tr key={index}>
                <td>{el.id}</td>
                <td>
                  <Status id={el.id} />
                </td>
                <td>{makeDate(el.createdAt)}</td>
                <InternalStatus id={el.id} />
                <td className="money">
                  {euroFrench.format(el.s_montant_global)}
                </td>
                <td>{el.timesContacted}</td>
                <td className="date">{makeDate(el.lastContactedDate)}</td>
                <td>
                  <Customer id={el.customer} />
                </td>
                <td>{el.s_email_agent}</td>
                <td className="actions">
                  <div className="d-flex items-center">
                    <Link
                      className="gray"
                      to={{ pathname: "/finance/request/" + el.id }}
                    >
                      <i className="icon-eye"></i>
                    </Link>
                    <Link
                      className="gray"
                      to={{ pathname: "/finance/edit-request/" + el.id }}
                    >
                      <i className="icon-fileedit"></i>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
