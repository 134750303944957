import React from 'react'
import RightArrow from 'assets/rightArrow.svg'
import DownArrow from 'assets/downArrow.svg'
import ArrowDownPhone from 'assets/arrowDownPhone.svg'
import DownRightArrow from 'assets/downRightArrow.svg'
import LeftArrow from 'assets/leftArrow.svg'
import './RoadMapCard.scss'
import { cards } from './RoadMapStaticData'

const RoadMap = ({ forwardRef }) => {
    return (
        <div ref={forwardRef}>
            <div className='road-map'>
                <span className='gray road-map-title'>Comment faire ma demande de financement?</span>
                <div className='d-flex first-bloc'>
                    {cards[0].code}
                    <img className='arrow-right' src={RightArrow} alt="arrow right" />
                    {cards[1].code}
                </div>
                <div className='second-bloc'>
                    <div />
                    <img src={DownArrow} alt="arrow down" className='arrow-down' />
                </div>
                <div className='d-flex first-bloc'>
                    {cards[3].code}
                    <img className='arrow-left' src={LeftArrow} alt="arrow left" />
                    {cards[2].code}
                </div>
                <div className='third-bloc'>
                    <img className='last-pic' src={DownRightArrow} alt="arrow down right" />
                    {cards[4].code}
                </div>
            </div>
            <div className='road-map-phone-vue'>
                <span className='gray road-map-title'>Comment faire ma demande de financement?</span>
                <div className='blocs'>
                    {cards[0].code}
                    <img className='arrow-down' src={ArrowDownPhone} alt="arrow down" />
                    {cards[1].code}
                </div>
                <div className='second-bloc'>
                    <img className='arrow-down-second' src={DownArrow} alt="arrow down" />
                </div>
                <div className='blocs'>
                    {cards[3].code}
                    <img className='arrow-down-second' src={DownArrow} alt="arrow down" />
                    {cards[2].code}
                </div>
                <div className='blocs'>
                    <img className='arrow-down-second' src={DownArrow} alt="arrow down" />
                    {cards[4].code}
                </div>
            </div>
        </div>
    )
}

export default RoadMap